import React, { PropsWithChildren } from "react";
export interface FullPageContentProps extends PropsWithChildren {
  icon: JSX.Element;
  className?: string;
  onNextClick?: () => void;
  onlyNext?: boolean;
  onSkipClick?: () => void;
}

const FullPageContent: React.FC<FullPageContentProps> = ({
  icon,
  children,
  onNextClick,
  onSkipClick,
  onlyNext,
}) => {
  return (
    <>
      <section className="flex flex-col items-center justify-center h-screen mx-6">
        {/* <div className="mx-4"></div> */}
        {icon}
        <div className="h-10" />
        <div className="mx-2">{children}</div>
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white flex justify-center">
          {!onlyNext ? (
            <button
              onClick={onSkipClick}
              className="btn-out border-kGreen-light text-gray-500 ml-4"
            >
              Back
            </button>
          ) : undefined}
          <div className="w-4"></div>
          <button onClick={onNextClick} className="btn bg-kGreen-light">
            Next
          </button>
        </div>
      </section>
    </>
  );
};

export default FullPageContent;
