import { FC } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { RouterItemEnum } from "../../../../../router/path";
import { getPriceWithComma } from "../../../../../utils/en2bn";
import AsyncValue from "../../../../shared/AsyncValue";
export interface ISettlementProps {
  loanId: string;
}

const Settlement: FC<ISettlementProps> = ({ loanId }) => {
  const settlementRes = useSWR(`/loan/settlement/early?loan_id=${loanId}`);
  return (
    <div>
      <p className="pl-4 text-xl py-2">Early Settlement</p>

      <AsyncValue
        res={settlementRes}
        onSuccess={(data: {
          due: string;
          fee: string;
          total_payable: string;
        }) => {
          return (
            <section className=" border-kGreen-dark  border-2 p-4 m-4 rounded-lg">
              <div className="flex justify-between p-4 items-center">
                <p className="text-2xl font-semibold">
                  {getPriceWithComma(data.total_payable)}
                </p>
                <Link
                  to={`${RouterItemEnum.Account}/${RouterItemEnum.AddWallet}?es=1`}
                  className={`bg-kGreen-light h-8 text-white px-3 py-1 rounded-lg`}
                >
                  Pay now
                </Link>
              </div>
              <p className="pl-4">{`Due (${getPriceWithComma(
                data.due
              )}) + Fee (${getPriceWithComma(data.fee)})`}</p>
            </section>
          );
        }}
      />
    </div>
  );
};
export default Settlement;
