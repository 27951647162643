import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitlePageLayout from '../../../components/Layouts/TitlePageLayout';
import { useLang } from '../../../components/hooks/useLang';
import { RouterItemEnum } from '../../../router/path';
import BtnPrimary from '../../shared/BtnPrimary';
import { ReactComponent as LoanCardIcon } from './assets/card_icon.svg';
import { ReactComponent as SC1 } from './assets/sc1.svg';
import { ReactComponent as SC2 } from './assets/sc2.svg';
import { ReactComponent as SC3 } from './assets/sc3.svg';
import { ReactComponent as SC4 } from './assets/sc4.svg';
import { ReactComponent as SC5 } from './assets/sc5.svg';
import { ReactComponent as SC6 } from './assets/sv6.svg';
import { ApplyItem } from './components/Applyitem';


export const HowToApplyrouteName = "how-to-apply-route"

export interface IHowToApplyProps {
}


export interface IHowLoanWorkCardProps {
}



export const HowLoanWorkCard: FC<IHowLoanWorkCardProps> = ({ }) => {
    const { t_loan, t } = useLang()
    const nav = useNavigate()



    return (

        <div>
            <div onClick={() => {
                nav(RouterItemEnum.loan + '/' + HowToApplyrouteName)
            }} className="flex  py-3  bg-kGreen-light/10 px-1.5 shadow-md border rounded-lg mx-3 justify-between">
                <div className="flex-1">
                    <LoanCardIcon />
                </div>
                <div className="w-4"></div>
                <div className="flex flex-col ">
                    <p className='text-lg font-semibold'>{t('digital__loan')}</p>
                    <p className='mt-2 text-sm text-gray-500'>{t_loan('check_here_to_know_that_how_to_apply_for_a_digital_loan__')}</p>
                </div>
            </div>

        </div>
    )
}


const HowToApply: FC<IHowToApplyProps> = ({ }) => {
    const [idx, setIdx] = useState(0)
    const nav = useNavigate()
    const { t_loan, t } = useLang()
    const howItWorks = [
        {
            icon: <SC1 />,
            title: t_loan('select_your_preferred_loan_amount_and_term.check_your_monthly_installment_and_fees.after_that_,_click_the_“proceed”_button._')
        },
        {
            icon: <SC2 />,
            title: t_loan('select_the_purpose_of_taking_loan_then_click_the_apply_button_to_submit_the_application.')
        },
        {
            icon: <SC3 />,
            title: t_loan('agree_to_the_terms_&_conditions_of_digital_loan_by_clicking_the_agree_button.')
        },
        {
            icon: <SC4 />,
            title: t_loan('complete_the_e-kyc_process_to_verify_your_identification')
        },
        {
            icon: <SC5 />,
            title: t_loan('give_your_home_and_office/business_address_for_verification.')
        },
        {
            icon: <SC6 />,
            title: t_loan('give_your_preferred_account_information_to_get_the_loan')
        },
        {
            icon: <SC6 />,
            title: `Give your preferred account information to get the loan.`
        },
    ]

    return (
        <TitlePageLayout title={t_loan('how_to_apply_digital_loan')} dontShowBottomNav>
            <div className="h-10"></div>
            <div className="flex justify-evenly mx-2">
                <div className={`h-2 w-10 ${idx === 0 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
                <div className={`h-2 w-10   ${idx === 1 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
                <div className={`h-2  w-10 ${idx === 2 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
                <div className={`h-2  w-10 ${idx === 3 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
                <div className={`h-2 w-10  ${idx === 4 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
                <div className={`h-2 w-10  ${idx === 5 ? 'bg-kGreen-light' : 'bg-gray-100'}  rounded-lg`} />
            </div>
            <div className="h-10"></div>
            {
                <ApplyItem icon={howItWorks[idx].icon} title={howItWorks[idx].title} />
            }
            <div className="h-10"></div>
            {/* <PaylaterWorkItem icon={howItWorks[idx].icon as ReactNode} title={howItWorks[idx].title} />
            <div className="h-10"></div> */}




            <div className="flex items-center justify-center">
                {idx === 5 ? null : <button className="btn-out btn-out-pri" onClick={() => nav(-1)}>{t('skip')}</button>}
                <div className="w-2.5"></div>

                {idx === 5 ? <BtnPrimary onClick={() => nav(RouterItemEnum.home, { replace: true })} >{t('back__to__home')}</BtnPrimary> : <BtnPrimary onClick={() => {
                    if (idx === 5) return
                    setIdx(idx + 1)
                }} >
                    {t('next')}
                </BtnPrimary>}
            </div>


        </TitlePageLayout>
    );
}
export default HowToApply
