import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AddressBreakdown } from "../../modules/shared/Address";
import AddressText from "../../modules/shared/Address/AddressText";
import { useNavigateAfterSubmit } from "../../modules/shared/Address/hooks/useNavigateAfterSubmit";

const TestPage: FC = () => {
  const nav = useNavigateAfterSubmit();
  // get search params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(Boolean(Object.fromEntries(searchParams)["type"]));
  }, [searchParams]);

  if (!Boolean(Object.fromEntries(searchParams)["type"])) {
    return <AddressText />;
  }

  return (
    <AddressBreakdown
      headingText={searchParams.get("title") ?? "Home Address"}
      type={(searchParams.get("type") as any) ?? "home"}
      afterSuccessFullSend={() => {
        if (searchParams.get("type") === "home") {
          setSearchParams({ title: "Office Address", type: "office" });
        } else {
          nav();
        }
      }}
    />
  );
};
export default TestPage;
