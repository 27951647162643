
import React from "react";
import { useLocation } from "react-router-dom";
import analytics from "./utils";
import { useUserInfo } from "../components/hooks/useUserInfo";

export default function useGoogleAnalytics() {
  const location = useLocation()
  const {id,username}= useUserInfo()
  React.useEffect(() => {
    analytics.init()
  }, [analytics])
  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    console.log("currentPath", currentPath)
    analytics.sendPageview({
      path: currentPath,
      location: currentPath,
      title: `${username}-${id}`,
    })
  }, [location])
}


