import { FC } from "react";
import Lottie from "react-lottie";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import thankanim from "../../../../assets/lottie/thank_you.json";
import { PaymentSchedulerouteName } from "../PaymentSchedule";
export const CPVDonerouteName = "cpv-done-route";

export interface ICPVDoneProps {}
const defaultOptions = {
  loop: 2,
  autoplay: true,
  animationData: thankanim,
  // rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  // }
};
const CPVDone: FC<ICPVDoneProps> = ({}) => {
  const { t } = useTranslation("bnpl");
  return (
    <div className="">
      <div className="h-[10vh]"></div>
      <div className="flex items-center flex-col justify-center  space-y-3">
        <Lottie options={defaultOptions} />
        <p className="font-bold text-2xl">{t("congratulations_!")}</p>
        <p className="text-gray-700 mx-6 text-center">
          {t(
            "your_bnpl_application_is_approved_successfully._get_ready_to_pay_the___________first_installment_now_and_product_will_be_delivered_to_your_door."
          )}
        </p>
      </div>
      {/*  fix button in bottom next */}
      <div className="fixed bottom-10 left-0 right-0">
        <div className="flex justify-center">
          <Link
            to={"/paylater/" + PaymentSchedulerouteName}
            className="btn bg-kGreen-light w-1/2 py-3"
          >
            {t("next")}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CPVDone;
