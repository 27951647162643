import { useNavigate } from "react-router-dom";


export const useGotoRoute = () => {
    const navigate = useNavigate();
    
    return (path: string, query?: any, pathParams?:any, state?: any) => {
        const url = buildRouterUrl(path, pathParams, query);
          navigate(url, { state });
    };
};

const buildRouterUrl = (path: string, pathParams?: any,query?:any) => {
    let url = path;
    if (pathParams) {
        Object.keys(pathParams).forEach((key) => {
            url = url.replace(`:${key}`, pathParams[key]);
        });
    }

    if (query) {
        const queryString = Object.keys(query)
            .map((key) => `${key}=${query[key]}`)
            .join("&");
        url += `?${queryString}`;
    }
    return url;
}
