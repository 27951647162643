import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useBoundStore from '../../state/store'



export interface TabItemProps extends PropsWithChildren {
    title: string,
    route: string,

}

const TabItem: FC<TabItemProps> = ({ title, route, children }) => {
    const { pathname } = useLocation()
    const [active, setActive] = useState(false)
    const { primaryColor } = useBoundStore()
    useEffect(() => {
        setActive(pathname === route)
        return () => {
        }
    }, [pathname, route])
    return (
        <Link to={route} className="inline-block py-2 ">
            <div className="flex flex-col space-y-1 justify-center items-center">
                {/* <div className="h-2">

                </div> */}
                {/* {active && <div className='border-t-2 border-kGreen-light'>Active</div>} */}

                {/* {active && <div className='h-2'></div>} */}
                <div style={{
                    stroke: '#008081'
                }}>

                    {children}
                </div>

                <span className={`block text-xs text-center ${active ? 'text-kGreen-dark' : 'text-gray-700'} `}>{title}</span>
            </div>
        </Link>
    )
}

export default TabItem
