import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IDocuments } from "../../../../credit";
import UploadImageInput from "../../../../credit/components/UploadImg.component";
import BtnPrimary from "../../../../shared/BtnPrimary";
interface BaseDocumentUploadFormProps
  extends React.ComponentProps<typeof Formik> {
  fields: IDocuments[];
}
const SubmitBtn = () => {
  const { isValid, errors } = useFormikContext();
  const { t } = useTranslation("common");

  return (
    <BtnPrimary type="submit" center>
      {t("complete")}
    </BtnPrimary>
  );
};
const BaseDocumentUploadForm: React.FC<BaseDocumentUploadFormProps> = ({
  fields,
  ...restProps
}) => {
  return (
    <Formik
      {...restProps}
      // initialValues={{} as Record<string, any>}
      // onSubmit={function (
      //   values: FormikValues,
      //   formikHelpers: FormikHelpers<FormikValues>
      // ): void | Promise<any> {
      //   throw new Error("Function not implemented.");
      // }}
    >
      <Form>
        {fields.map((it, i) => (
          <UploadImageInput
            key={i}
            label={it.placeholder}
            id={it.slug}
            name={it.slug}
            required={it.required === "1"}
          />
        ))}
        <div className="h-2"></div>
        <SubmitBtn />
      </Form>
    </Formik>
  );
};

export default BaseDocumentUploadForm;
