import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import TitlePageLayout from "../../../../components/Layouts/TitlePageLayout";
import { RouterItemEnum } from "../../../../router/path";
import { getPriceWithComma } from "../../../../utils/en2bn";
import { getDaysLeft } from "../../../paylater/pages/PaymentSchedule";
import PaymentItem from "../../../paylater/pages/PaymentSchedule/components/PaymentItem";
import { IScheduleDetails } from "../../../paylater/pages/PaymentSchedule/types";
import AsyncValue from "../../../shared/AsyncValue";
import NoItemFound from "../../../shared/NoItemFound";
import Settlement from "./Settelment";

export const PaymentLoanSchedulerouteName = "payment";

export interface IPaymentLoanScheduleProps {}

const PaymentLoanSchedule: FC<IPaymentLoanScheduleProps> = () => {
  const { t } = useTranslation(["main", "bnpl"]);
  const paymentScheduleRes = useSWR<IScheduleDetails>("/loan/repay/schedules");

  return (
    <TitlePageLayout title={t("main:my_payments")} dontShowBottomNav>
      <div className="h-4" />

      <AsyncValue<IScheduleDetails, any>
        res={paymentScheduleRes}
        noItemMsg={
          <p className="text-center">{t("bnpl:no_payment_schedules_found")}</p>
        }
        onSuccess={function (data: IScheduleDetails): JSX.Element {
          const schedule = data?.schedule || [];
          const currentPay = schedule.findIndex(
            (item) => item.allow_pay || item.status === "pending"
          );

          const otherPay = schedule.filter(
            (item) =>
              !item.allow_pay && !["pending", "paid"].includes(item.status)
          );

          const paidPay = schedule.filter(
            (item) => !item.allow_pay && ["paid"].includes(item.status)
          );

          if (data?.schedule?.length === 0 || !data?.schedule) {
            return (
              <NoItemFound
                message={
                  <p className="text-center">
                    {t("bnpl:no_upcoming_payment_schedule")}
                  </p>
                }
              />
            );
          }

          return (
            <>
              {currentPay >= 0 ? (
                <PaymentItem
                  amount={getPriceWithComma(schedule[currentPay].amount)}
                  installmentNo={schedule[currentPay].installment_no}
                  hasOverDue={parseInt(data?.overdue || "0") !== 0}
                  footerTxt={
                    parseInt(data.overdue || "0") !== 0 ? (
                      <p className="text-sm pl-4 italic">{`with overdue charge (${data.overdue})`}</p>
                    ) : undefined
                  }
                  timeSince={
                    "Due on " +
                    moment(schedule[currentPay].date).format("MMM D ,YYYY")
                  }
                  action={
                    schedule[currentPay].status === "pending" ? (
                      <p className="bg-kAmber h-8 text-white px-3 py-1 rounded-lg">
                        {t("bnpl:pending")}
                      </p>
                    ) : (
                      <Link
                        to={`${RouterItemEnum.Account}/${RouterItemEnum.AddWallet}`}
                        className={`bg-kGreen-light h-8 text-white px-3 py-1 rounded-lg`}
                      >
                        {t("bnpl:_pay_now")}
                      </Link>
                    )
                  }
                />
              ) : undefined}

              <p className="pl-4 text-xl py-2">{t("bnpl:upcoming_payments")}</p>

              {otherPay.length > 0
                ? otherPay.map((item, index) => {
                    return (
                      <PaymentItem
                        key={index}
                        amount={getPriceWithComma(item.amount)}
                        installmentNo={item.installment_no}
                        action={
                          <p className="text-sm">
                            {moment(item.date).format("MMM D , YYYY")}
                          </p>
                        }
                        timeSince={`${getDaysLeft(item.date)} days left`}
                      />
                    );
                  })
                : undefined}

              <Settlement loanId={data.loan_id} />

              {paidPay.length > 0 ? (
                <p className="pl-4 text-xl py-2">Completed Payments</p>
              ) : undefined}

              {paidPay.length > 0
                ? paidPay.map((item, index) => {
                    return (
                      <PaymentItem
                        key={index}
                        amount={getPriceWithComma(item.amount)}
                        installmentNo={item.installment_no}
                        action={
                          <p className="px-4 py-1 bg-kGreen-light/60 text-white rounded-lg">
                            {t("bnpl:_paid")}
                          </p>
                        }
                        timeSince={`Paid on ${moment(item.paid_at).format(
                          "MMM D ,YYYY"
                        )}`}
                      />
                    );
                  })
                : undefined}
            </>
          );
        }}
      />
    </TitlePageLayout>
  );
};
export default PaymentLoanSchedule;
