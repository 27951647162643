import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RouterItemEnum } from "../../../router/path";
import { makePath } from "../../../router/utils";
const OneMoreStep = lazy(() => import("../../shared/OneMoreStep"))
const AddressVerificationPage = lazy(() => import("../pages/AddressVerification"))
const FacialRecognation = lazy(() => import("../pages/FacialRecognation"))
const NidVerify = lazy(() => import("../pages/NidVerify"))
const StartVerification = lazy(() => import("../pages/StartVerification"))
const VerifyInfo = lazy(() => import("../pages/VerifyInfo"))

export const EKYCRoutes: RouteObject[] | undefined = [
    {
        index: true, element:

            <StartVerification />


    },
    makePath(RouterItemEnum.NIDFont,
        <NidVerify />
    ),

    makePath(RouterItemEnum.FacialVerification,
        <FacialRecognation />
    ),

    makePath(RouterItemEnum.VerifyInfo,
        <VerifyInfo />

    ),
    makePath(RouterItemEnum.OneMoreStep, <OneMoreStep />
    ),
    makePath(RouterItemEnum.AddressVerificationPage, <AddressVerificationPage />
    ),
]