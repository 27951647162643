import { Field, FieldProps, useFormikContext } from "formik";
import { FC } from "react";

export interface IUploadImageInputProps {
  label: string;
  id: string;
  name: string;
  innertxt?: string;
  required?: boolean;
}

const UploadImageInput: FC<IUploadImageInputProps> = ({
  label,
  id,
  name,
  required,
}) => {
  const { values } = useFormikContext<any>();
  return (
    <div className="mx-6">
      <p className="py-4 font-semibold text-gray-500">
        {label}
        {required && <span className="text-red-500"> *</span>}
      </p>
      <div className="flex items-center justify-center w-full ">
        <label
          htmlFor={id}
          className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {values[name] ? (
              <p className="text-gray-500">{values[name]["name"]}</p>
            ) : (
              <div className="flex space-x-2 items-center justify-center">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span>{" "}
                </p>
              </div>
            )}
          </div>
          <Field name={name}>
            {(f: FieldProps) => (
              <>
                <input
                  onChange={(e) => {
                    if (e.currentTarget.files) {
                      f.form.setFieldValue(name, e.currentTarget.files[0]);
                    }
                  }}
                  id={id}
                  name={name}
                  required={required}
                  type="file"
                  className="hidden"
                />
              </>
            )}
          </Field>
        </label>
      </div>
    </div>
  );
};
export default UploadImageInput;
