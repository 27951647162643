import { Modal, TextInput } from "flowbite-react";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { NAGAD_REDIRECT_URL } from "../../../constants";
import { useRequestForMutation } from "../../../modules/hook/useRequest";
import AsyncValue, {
  AsyncMutationRequest,
} from "../../../modules/shared/AsyncValue";
import BtnPrimary from "../../../modules/shared/BtnPrimary";
import useBoundStore from "../../../state/store";
import TitlePageLayout from "../../Layouts/TitlePageLayout";
import AdaptBox from "../../shared/AdaptBox";
import { IRepayAccount, IRepayAccountProvider } from "./types";

export interface IAddWalletProps {}

export const RepayAccountModal: FC<{
  type: string;
  open?: boolean;
  onClose: (() => void) | undefined;
  onDone: VoidFunction;
}> = ({ open, onClose }) => {
  return (
    <Modal show={open} onClose={onClose}>
      <Modal.Header>Add Your Phone</Modal.Header>
      <ModalBody>
        <TextInput placeholder="e.g:01724944009" />
      </ModalBody>
      <Modal.Footer>
        <BtnPrimary>Confirm</BtnPrimary>
      </Modal.Footer>
    </Modal>
  );
};

const RepayAccountItem: FC<IRepayAccount> = ({
  icon,
  provider_name,
  ac_id,
  isForEarlySettlement,
}) => {
  const { userLoanStatus, setPayingEarlySettlment } = useBoundStore();
  const { trigger, ...restProps } = useRequestForMutation(
    `/loan/repay/pg/${provider_name.toLowerCase()}`
  );

  const handleRequest = () => {
    trigger(
      {
        postBody: {
          ac_id,
          loan_id: userLoanStatus?.id,
          callback_url: NAGAD_REDIRECT_URL,
          early_settlement: Boolean(isForEarlySettlement),
        },
      },
      {
        onSuccess(data) {
          setPayingEarlySettlment(Boolean(isForEarlySettlement));

          window.open(data.url);
        },
      }
    );
  };

  // const requestUrl = useswrImmutable<any>([provider_name, paymentSechdule?.loan_id, ac_id], ([provider, loanId, ac_id]) => fetchSandboxUrl((provider as string).toLowerCase(), loanId!, ac_id, userInfo?.token))

  return (
    <AsyncMutationRequest<any, any>
      res={restProps}
      onIdle={
        <div
          className="flex space-x-6 items-center my-4"
          onClick={handleRequest}
        >
          <div className="p-2 bg-gray-50 border rounded-lg shadow-sm">
            <img src={icon} className="w-10 h-10" alt={provider_name} />
          </div>
          <p className="font-medium text-xl text-gray-700">{provider_name}</p>
        </div>
      }
      onSuccess={function (data: any): JSX.Element {
        return (
          <div></div>
          // <div
          //   className="flex space-x-6 items-center my-4"
          //   onClick={handleRequest}
          // >
          //   <div className="p-2 bg-gray-50 border rounded-lg shadow-sm">
          //     <img src={icon} className="w-10 h-10" alt={provider_name} />
          //   </div>
          //   <p className="font-medium text-xl text-gray-700">{provider_name}</p>
          // </div>
          //   <a href={data.url} className="flex space-x-6 items-center my-4">
          //   </a>
        );
      }}
    />
  );
};

const AddWallet: FC<IAddWalletProps> = () => {
  const [qp, _] = useSearchParams();

  const isEarlySettment = Boolean(qp.get("es"));
  const repayAccountReq =
    useSWRImmutable<IRepayAccountProvider>("/repay/accounts");

  // const { routerCtx } = useBoundStore()
  const [openModal, setOpenModal] = useState(false);

  return (
    <TitlePageLayout title={"Payment Method"} dontShowBottomNav>
      <div className="my-6 px-6">
        <AsyncValue<IRepayAccountProvider, any>
          res={repayAccountReq}
          onSuccess={function (data: IRepayAccountProvider): JSX.Element {
            return (
              <div className="flex flex-col h-[90vh] justify-between">
                <div className="">
                  <div className="flex flex-col">
                    {data.accounts.map((account, index) => (
                      <RepayAccountItem
                        key={index}
                        {...account}
                        isForEarlySettlement={isEarlySettment}
                      />
                    ))}
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center mb-6">
                  <AdaptBox
                    classNames="btn w-1/2 text-white"
                    onlyBg
                    onClick={() => setOpenModal(!openModal)}
                  >
                    Confirm
                  </AdaptBox>
                </div>
              </div>
            );
          }}
        />
      </div>
    </TitlePageLayout>
  );
};
export default AddWallet;
