
import { useTranslation } from 'react-i18next';

function convertToBengaliLocale(dateString: string, local: 'bn-BD' | 'en-US', isUnix: boolean = false): string {

    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(isUnix ? +dateString * 1000 : dateString);
    return date.toLocaleString(local, {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    }).replace(/\d+/g, (digit) => String.fromCharCode(+digit + 2534));
}
export const useLang = () => {
    const { t, i18n } = useTranslation();
    const { t: t_main } = useTranslation('main');
    const { t: t_loan } = useTranslation('loan');
    const isLangEnglish = i18n.language.includes('en')

    const getPriceWithComma = (price: any) => {

        if (['0', 0].includes(price)) return isLangEnglish ? '৳ 0.00' : '৳ ০.০০'
        return `৳ ${parseInt(price).toLocaleString(isLangEnglish ? 'en-US' : 'bn-BD')}`
    }



    const getLocalDateTime = (dateTimeStr: string, isUnix: boolean) => convertToBengaliLocale(dateTimeStr, isLangEnglish ? 'en-US' : 'bn-BD', isUnix)


    const replaceWithBangla = (enTxt: string, value: string) => {
        if (isLangEnglish) return enTxt
        return value
    }

    const convertToBanglaNumber = (num: string) => {



        if (isLangEnglish) {
            return num;
        }
        const banglaNumbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

        let numStr = num;
        let banglaStr = "";

        for (let i = 0; i < numStr.length; i++) {
            const digit = parseInt(numStr[i]);

            if (isNaN(digit)) {
                banglaStr += numStr[i];
            } else {
                banglaStr += banglaNumbers[digit];
            }
        }
        return banglaStr;
    }




    return {
        t,
        t_loan,
        t_main,
        lang: i18n.language,
        getPriceWithComma,
        getLocalDateTime,
        convertToBanglaNumber,
        replaceWithBangla,
        isLangEnglish
    }


}