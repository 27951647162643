import { IonCard, IonItem } from "@ionic/react";
import { Alert } from "flowbite-react";
import Parser from "html-react-parser";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { ReactComponent as Congratulation } from "../../../../assets/iconset/icons/congratulations.svg";
import httpClient from "../../../../client/axios";
import TitlePageLayout from "../../../../components/Layouts/TitlePageLayout";
import { RouterItemEnum, makeRoute } from "../../../../router/path";
import useBoundStore from "../../../../state/store";
import useLoanStatus from "../../../hook/useLoanStatus";
import { useRequestForMutation } from "../../../hook/useRequest";
import { ILoanCheckStatus } from "../../../score/types";
import { AsyncMutationRequest } from "../../../shared/AsyncValue";
import BtnPrimary from "../../../shared/BtnPrimary";
import Loading from "../../../shared/Loading";
import EMIShow from "../../components/EmiShow/EMIShow";

import { GotItModal } from "../../../../components/shared/Modal/GotItModal";

export const BNPLDetailsrouteName = "bnpl-details-route";

export interface IBNPLDetailsProps {}

const BNPLDetails: FC<IBNPLDetailsProps> = ({}) => {
  const { userLoanStatus, userConfig, selectProduct } = useBoundStore();

  const [isChecked, setIsChecked] = useState(false);
  const navigator = useNavigate();
  const { t } = useTranslation("bnpl");
  const {
    trigger,
    isMutating,
    error: err,
  } = useRequestForMutation("/bnpl/orders/cancel");
  const {
    trigger: triggerAccept,
    isMutating: accpting,
    error: acceptError,
  } = useRequestForMutation("/bnpl/orders/accept");

  const {
    data: urlsData,
    error: aggrementError,
    isLoading,
  } = useSWRImmutable(
    Boolean(userLoanStatus?.order.id)
      ? `/bnpl/agreement?order_id=${userLoanStatus?.order.id}&&html=true`
      : null,
    (url) => {
      return httpClient.get(url);
    }
  );

  return (
    <TitlePageLayout title={t("bnpl_details")} dontShowBottomNav>
      <div className="mx-6 my-4">
        <div className="flex flex-col items-center p-6 shadow-lg rounded-lg">
          <div className=" rounded-full shadow-lg bg-gray-50 h-20 w-20 flex items-center justify-center">
            <Congratulation className="w-10" />
          </div>
          <div className="h-6"></div>

          <div className="flex items-center flex-col">
            <div className="text-kGreen-light font-medium">
              {t("congratulations_!")}
              {/* {t("congratulations")} */}
            </div>
            <div className="text-gray-500 text-center">
              {t("your_bnpl_application_is_approved.")}
              {/* {t("your__bnpl__application__is__approved__for")} */}
            </div>
          </div>
        </div>

        <div className="h-4" />
        <div className="btn bg-kGreen-light py-3 my-2">
          <div className="flex justify-between">
            <p>{selectProduct?.name ?? ""}</p>
            <p>৳{selectProduct?.price ?? ""}</p>
          </div>
        </div>
        <div className="h-4"></div>
        <IonCard>
          <IonItem>
            <div className="flex justify-between w-full">
              <h3 className="text-gray-500 text-sm">{t("service_charge")}</h3>
              <p> ৳ {userLoanStatus!.fee_details.service_charge}</p>
            </div>
          </IonItem>
          <IonItem>
            <div className="flex justify-between w-full">
              <h3 className="text-gray-500 text-sm">{t("delivery_charge")}</h3>
              <p> ৳ {userLoanStatus!.fee_details.delivery_charge}</p>
            </div>
          </IonItem>
        </IonCard>
        <div className="h-6"></div>

        <EMIShow
          emiNumber={parseInt(
            userLoanStatus?.tenor?.replace(" months", "") ?? "0"
          )}
          fristAmount={`৳${
            +userLoanStatus!.monthly_emi +
            +userLoanStatus!.fee_details.service_charge +
            +userLoanStatus!.fee_details.delivery_charge
          }`}
          emiAmount={`৳${userLoanStatus!.monthly_emi}`}
          fees={
            <p className="text-sm text-gray-400">
              ({`৳${userLoanStatus!.monthly_emi}`}+ ৳
              {userLoanStatus!.fee_details.service_charge} +৳
              {userLoanStatus!.fee_details.delivery_charge})
            </p>
          }
        />

        <div className="h-6"></div>

        {isLoading && <Loading dontTakefullPageWidth />}

        <div className="flex flex-col items-center ">
          <div className="flex items-center space-x-4 mb-4">
            <input
              id="agree-checkbox"
              type="checkbox"
              onChange={(_) => setIsChecked(!isChecked)}
              className="w-4 h-4 text-kGreen-dark bg-gray-100 rounded border-gray-300 focus:ring-kGreen-dark dark:focus:ring-kGreen-dark dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />

            <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex space-x-1">
              <p>{t("i_have_read_and_agreed_to_bnpl")} </p>
              {isLoading ? (
                <Loading dontTakefullPageWidth />
              ) : (
                <GotItModal
                  Body={
                    <div
                      dangerouslySetInnerHTML={
                        {
                          __html: urlsData?.data ?? "",
                        } as any
                      }
                    ></div>
                  }
                >
                  {/* <p>Got It Modal</p> */}
                  <p className="text-kGreen-light">{t("agreement")}</p>
                </GotItModal>
              )}
            </label>
          </div>

          <div className="h-4"></div>
          {accpting && <Loading dontTakefullPageWidth />}
          {isMutating && <Loading dontTakefullPageWidth />}

          {acceptError ? (
            <Alert color="failure">{acceptError.message}</Alert>
          ) : (
            <></>
          )}
          <div className="h-4"></div>
          {
            <div className="flex">
              <button
                className="btn-out border-red-500"
                onClick={() => {
                  trigger(
                    {
                      postBody: {
                        reason: "I dont like it",
                      },
                    },
                    {
                      onSuccess: () => {
                        navigator(RouterItemEnum.home);
                      },
                    }
                  );
                }}
              >
                {t("cancel")}
              </button>
              <div className="w-2"></div>

              <BtnPrimary
                disable={!isChecked}
                onClick={() => {
                  triggerAccept(
                    {
                      postBody: {},
                    },
                    {
                      onSuccess() {
                        navigator(
                          makeRoute([
                            RouterItemEnum.common,
                            RouterItemEnum.thankYou,
                            `${
                              userConfig?.contact_point_verification?.required
                                ? `?content=${t(
                                    "your_bnpl_application_is_under_process._a_contact_point_verification_agency_will_contact_you_to_verify_your_addresses._we_will_appreciate_your_co-operation._please_note_that_if_you_don’t_get_any_update_in_next_24_hours,_please_call"
                                  )}`
                                : `?content=` +
                                  t(
                                    "your_bnpl_product_will_be_delivered_soon.please_note_that_if_you_dont_get_any_update_in_next_48_hours_please_call"
                                  )
                            }`,
                            //
                          ])
                        );
                      },
                    }
                  );
                }}
              >
                {t("agree")}
              </BtnPrimary>
              {urlsData?.data && <>{Parser(urlsData.data)}</>}
            </div>
          }
        </div>
      </div>
    </TitlePageLayout>
  );
};

const FetchLoanStatusAndRender = () => {
  const req = useLoanStatus();
  return (
    <AsyncMutationRequest<ILoanCheckStatus, any>
      res={req}
      onSuccess={function (data: ILoanCheckStatus): JSX.Element {
        return <BNPLDetails />;
      }}
    />
  );
};
export default FetchLoanStatusAndRender;
