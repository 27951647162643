import { Card } from "flowbite-react";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { TbCapture } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import FaceVerify from "../../../../assets/lottie/face_varify.json";
import { useGetHttpClient } from "../../../../client/axios";
import TitlePageLayout from "../../../../components/Layouts/TitlePageLayout";
import SvgAnimation from "../../../../components/shared/SvgAnimation";
import { useRequestForMutation } from "../../../hook/useRequest";
import AlertPrimary from "../../../loan/components/AlertPrimary";
import { AsyncMutationRequest } from "../../../shared/AsyncValue";
import BtnPrimary from "../../../shared/BtnPrimary";
import Loading from "../../../shared/Loading";
import { BNPLAddressVerifyPagerouteName } from "../AddressVerify";

export const BNPLFaceUploadrouteName = "face-upload-route";

export interface IBNPLFaceUploadProps {}

const BNPLFaceUpload: FC<IBNPLFaceUploadProps> = ({}) => {
  const [faceImg, setfaceImg] = useState<File>();
  const [uploadStart, setUploadStart] = useState<boolean>(false);
  const imageInpRef = useRef<any>();
  const client = useGetHttpClient();
  const nav = useNavigate();
  const { t } = useTranslation("bnpl");

  const { trigger, ...restProps } = useRequestForMutation("/user/assets");

  const handleImage = async () => {
    setUploadStart(true);

    let imageUploadData: any = new FormData();
    if (!faceImg) return;

    imageUploadData.append("photo", faceImg);

    trigger(
      {
        postBody: imageUploadData,
        hasFile: true,
      },
      {
        onSuccess(data) {
          let photo = "";
          try {
            photo = data["files"][0]["url"];
          } catch (error) {}
          client("/user", "POST", {
            data: {
              photo,
            },
          }).finally(() => {
            nav("/paylater/" + BNPLAddressVerifyPagerouteName);
          });
        },
      }
    );
  };

  return (
    <TitlePageLayout title={t("facial_verification")} dontShowBottomNav>
      <div className="flex flex-col items-center space-y-7">
        <input
          accept="image/*"
          capture="user"
          ref={imageInpRef}
          onChange={(e) => {
            if (e.target.files) setfaceImg(e.target.files[0]);
          }}
          placeholder={"Open Camera"}
          data-cy="face-bnpl"
          className="invisible w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          id={"image_font"}
          type="file"
        />
        <p>{t("take_a_selfie")}</p>
        <div className="h-4"></div>
        {faceImg ? (
          <Card>
            <img
              className="h-30 w-24 object-fit"
              src={URL.createObjectURL(faceImg)}
            />
          </Card>
        ) : (
          <SvgAnimation
            animationData={FaceVerify}
            style={{
              width: 200,
              height: 200,
            }}
          />
        )}

        <AsyncMutationRequest
          res={restProps}
          onLoading={<Loading dontTakefullPageWidth />}
          onSuccess={function (data: any): JSX.Element {
            return (
              <AlertPrimary type="success">
                {t("image_uploaded_successfully")}
              </AlertPrimary>
            );
          }}
        />

        <div className="h-6"></div>
        <div className="">
          <div
            className="p-2 bg-gray-100 rounded-lg"
            onClick={() => imageInpRef.current.click()}
          >
            {faceImg ? (
              <MdRefresh color="#008081" size={40} />
            ) : (
              <TbCapture color="#008081" size={40} />
            )}
          </div>
        </div>

        <p>{t("stay_steady_when_you_take_selfie_")}</p>

        <div className="h-4"></div>

        <BtnPrimary
          disable={faceImg ? false : true}
          center
          onClick={handleImage}
        >
          {t("next")}
        </BtnPrimary>
      </div>
    </TitlePageLayout>
  );
};
export default BNPLFaceUpload;
