

import { ChangeEventHandler, FC, HTMLInputTypeAttribute } from 'react';

export interface ITextInputProps {
    label: string,
    name: string,
    type?: HTMLInputTypeAttribute | undefined,
    placeHolder?: string | undefined,
    required?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined


}

const TextInput: FC<ITextInputProps> = ({ label, name, type, placeHolder, required, onChange }) => {


    return (<div className="mb-6">
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
        <input type={type} id={name} onChange={onChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-kGreen-dark block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-kGreen-light dark:focus:border-blue-500" placeholder={placeHolder} required={required} />
    </div>);
}
export default TextInput
