import  useSWR  from 'swr';
import useBoundStore from '../../state/store';
import { ILoanCheckStatus } from '../score/types';
const useLoanStatus =()=>{
    const {setCheckLoanStatus}= useBoundStore()
    return useSWR<ILoanCheckStatus>('/loan/status',null,{
        onSuccess(data){
            setCheckLoanStatus(data)
        }
    })
}

export default useLoanStatus