import { FC } from "react";

import { useSearchParams } from "react-router-dom";
import { useLang } from "../../../components/hooks/useLang";
import useBoundStore from "../../../state/store";
import BtnPrimary from "../BtnPrimary";
import { ReactComponent as LocationIcon } from "./assets/loc.svg";

export interface IAddressTextProps {
  params?: Record<string, string>;
}

const AddressText: FC<IAddressTextProps> = ({
  params = {
    title: "Home Address",
    type: "home",
  },
}) => {
  const { userConfig: usrConfig } = useBoundStore();
  const { t_main } = useLang();
  const [_, setSearchParams] = useSearchParams();
  return (
    <div className="m-8 ">
      <LocationIcon />

      <p className="text-center text-gray-500 my-20">
        {Boolean(usrConfig?.contact_point_verification?.required)
          ? t_main(
              "Please_provide_your_current_residential_and_business/office_addresses._In_order_to_finalize_the_approval_of_your_application,_third_party_contact_point_verification_(CPV)_provider_will_verify_your_addresses."
            )
          : t_main("without_cpv_text")}
      </p>
      <BtnPrimary
        onClick={() => {
          setSearchParams(params);
        }}
        center
      >
        <span className="text-sm">Next</span>
      </BtnPrimary>
    </div>
  );
};
export default AddressText;
