import { FC, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import useBoundStore from '../../state/store'
import AdaptBox from '../shared/AdaptBox'

type Props = {
    btnTitle?: string,
    route?: string,
    child?: ReactNode
}

const BottomButtonLayout: FC<Props> = ({ btnTitle = <Trans i18nKey={"back__to__home"} />, route = "/home", child },) => {
    const navigate = useNavigate()
    const { routerCtx, changeRouterCtx } = useBoundStore();
    return (
        <div className='flex flex-col items-center'>
            <div className="min-h-[90vh]  w-full flex flex-col items-center justify-center">
                {child ? child : <Outlet />}
            </div>
            <div className="mb-8">
                <AdaptBox gradientPrimary classNames="btn w-full" onClick={() => {
                    if (routerCtx === "EarnedWage") {
                        changeRouterCtx("Loan")
                    }
                    navigate(route)
                }}> {btnTitle}</AdaptBox>
            </div>
        </div>
    )
}

export default BottomButtonLayout