

import { ChangeEventHandler, FC, PropsWithChildren } from 'react';


export interface ISelectOptionProps extends PropsWithChildren {
    label: string
    name: string
    onChange?: ChangeEventHandler<HTMLSelectElement>
    options: string[],

}

const SelectOption: FC<ISelectOptionProps> = ({ label, name, options, onChange, children }) => {

    return (<div className='my-4'>
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">{label}</label>
        <select onChange={onChange} id={name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-kGreen-light focus:border-kGreen-light block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {children ?? options.map((item) => <option value={item}>{item}</option>)}
            {/*  */}
        </select>
    </div>)

}
export default SelectOption
