import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import BottomButtonLayout from "../components/Layouts/BottomButtonLayout";
import AccountMain from "../components/pages/Account/AccountMain";
import AddBank from "../components/pages/Account/AddBank";
import AddWallet from "../components/pages/Account/AddWallet";
import { RouterCreditCard } from "../modules/credit/routes";
import creditCardRoutes from "../modules/credit/routes/router";
import { EKYCRoutes } from "../modules/ekyc/routes/routes";
import { loanRouters } from "../modules/loan/routers/router";
import paylaterRoutes from "../modules/paylater/routers/router";
import { scoreRoutes } from "../modules/score/routes/route";
import HolderPage from "../pages/HolderPage";
import TestPage from "../pages/TestPage/TestPage";
import ErrorPage from "../pages/error/Error";
import NotFoundErrorPage from "../pages/error/Error/404";
import { commonRoutes } from "./common";
import { RouterItemEnum } from "./path";
import { makePath } from "./utils";
const ThankYou = lazy(() => import("../modules/shared/ThankYou"));
const HomePage = lazy(() => import("../pages/Home"));

const router = createBrowserRouter([
  {
    path: "/",

    errorElement: <ErrorPage />,

    children: [
      {
        index: true,
        element: <HolderPage />,
      },
      {
        path: "/home",

        element: <HomePage />,
      },
      {
        path: "/test",

        element: <TestPage />,
      },
      // *? Loan Routes
      ...loanRouters!,
      // ** Paylater Routes
      {
        path: RouterItemEnum.payLater,
        children: paylaterRoutes,
      },
      // {
      //   path: RouterItemEnum.sod,
      //   children: sodRoutes,
      // },
      // *! CreditCard Routes
      {
        path: RouterCreditCard.CreditCard,
        children: creditCardRoutes,
      },
      // ** Score Page
      {
        path: RouterItemEnum.Score,
        children: scoreRoutes,
      },

      // *! DPS Routes
      // {
      //   path: "/dps",
      //   children: DPSRoutesConfig,
      // },

      // *! Account Routes
      {
        path: RouterItemEnum.Account,
        children: [
          {
            index: true,
            element: <AccountMain />,
          },
          makePath(RouterItemEnum.AddBank, <AddBank />),
          makePath(RouterItemEnum.AddWallet, <AddWallet />),
        ],
      },

      // EKYC

      {
        path: RouterItemEnum.Ekyc,
        children: EKYCRoutes,
      },
      {
        path: RouterItemEnum.common,
        element: <BottomButtonLayout />,
        children: [
          {
            path: RouterItemEnum.thankYou,
            element: <ThankYou />,
          },
        ],
      },
      //Reward
      // {
      //   path: RouterItemEnum.RewardHome,
      //   children: rewardRoutes,
      // },

      // ETC PATH
      ...commonRoutes,
    ],
  },

  {
    path: "*",
    element: <NotFoundErrorPage />,
  },
]);

export default router;
