
import { useUserInfo } from "../components/hooks/useUserInfo";
import { TRACKING_ID } from "../constants"

import GA4React from "ga-4-react";

function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  const ga4 = new GA4React(
      TRACKING_ID!,
      {
        debug_mode: isDev,
        send_page_view:true
      });

    ga4.initialize().then((ga4) => {
      console.log("ga4", ga4)
      ga4.pageview('Intialize Session')
    }
    ).catch((err) => {
      console.log("err", err)
    }
  )
  
  
}

export function sendEvent({action,label,data,nonInteraction}:{action: string, label: string, data: string, nonInteraction?: boolean | undefined}) {
 
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
  const ga4React = GA4React.getGA4React()
  if(!ga4React) return
  ga4React.gtag('event','module_name',action,label,data,nonInteraction)

}



export const sendCustomEvent = (...payload: any) => {
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
  const ga4React = GA4React.getGA4React()
  if(!ga4React) return
  ga4React.gtag('event',...payload)
}

 interface PageView {
  path: string | Location, location?: string | Location, title?: string
}
export function sendPageview(e:PageView) {
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
   const ga4React = GA4React.getGA4React(); 
  if(!ga4React) return
  ga4React.pageview(e.path,e.location,e.title) 
 
}

export const sendEventWithParmas = (action: string, label: string, data: string, nonInteraction?: boolean | undefined)=>{
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
  const ga4React = GA4React.getGA4React(); 
  if(!ga4React) return
  ga4React.event(action,label,data,nonInteraction)

}

//make interface for user event
export interface UserEvent {
  action: string,
  label?: string,
  data?: string,
  nonInteraction?: boolean | undefined
}
export const useUserEventAnalytics = ()=>{
  const {score,id,username,credit_limit} = useUserInfo()
  try{

    const isInit = GA4React.isInitialized()
   
    const ga4React = GA4React.getGA4React(); 
    
    return (e:UserEvent) => ga4React!.event(e.action,e.label??`${username}-${id}`,e.data??`limit- ${credit_limit} score-${score}`,e.nonInteraction)
  }catch(e){
    console.log("error",e)
    return ()=>{}
  }


}

export default {
  init,
  sendEvent,
  sendPageview,
}