import React, { ComponentProps } from "react";
interface SelectFieldProps extends ComponentProps<"select"> {
  label: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  placeholder,

  required = true,
  ...restProps
}) => {
  return (
    <>
      <div className="my-4" key={name}>
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>

        <select
          placeholder={placeholder}
          name={name}
          id={name}
          cy-type="select"
          // {...field}
          required={required}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-kGreen-light focus:border-kGreen-light block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          {...restProps}
        />
      </div>
    </>
  );
};

export default SelectField;
