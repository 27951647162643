import { useReducer } from "react";
import useSWR from "swr";
const initialState = {
    division: "",
    district: "",
    subDistrict: "",
    postCode: "",
    fullAddress: "",
    divisionList: Array<{ id: string; name: string }>(),
    districtList: Array<{ id: string; name: string }>(),
    subDistrictList: Array<{ id: string; name: string }>(),
  };
  

  type Action =
    | { type: "SET_DIVISION"; payload: string }
    | { type: "SET_DISTRICT"; payload: string }
    | { type: "SET_SUB_DISTRICT"; payload: string }
    | { type: "SET_POST_CODE"; payload: string }
    | { type: "SET_FULL_ADDRESS"; payload: string }
    | { type: "SET_DIVISION_LIST"; payload: { id: string; name: string }[] }
    | { type: "SET_DISTRICT_LIST"; payload: { id: string; name: string }[] }
    | { type: "SET_SUB_DISTRICT_LIST"; payload: { id: string; name: string }[] };
  
  const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
      case "SET_DIVISION":
        return {
          ...state,
          division: action.payload,
          district: "",
          subDistrict: "",
        };
      case "SET_DISTRICT":
        return {
          ...state,
          district: action.payload,
        };
      case "SET_SUB_DISTRICT":
        return {
          ...state,
          subDistrict: action.payload,
        };
      case "SET_POST_CODE":
        return {
          ...state,
          postCode: action.payload,
        };
      case "SET_FULL_ADDRESS":
        return {
          ...state,
          fullAddress: action.payload,
        };
      case "SET_DIVISION_LIST":
        return {
          ...state,
          divisionList: action.payload,
        };
      case "SET_DISTRICT_LIST":
        return {
          ...state,
          districtList: action.payload,
        };
      case "SET_SUB_DISTRICT_LIST":
        return {
          ...state,
          subDistrictList: action.payload,
        };
      default:
        return state;
    }
  };


export const useAddressBreakDown = ()=>{
    const [state, dispatch] = useReducer(reducer, initialState);
    useSWR("/config/address/bd/divisions", null, {
      onSuccess(data, key, config) {
        dispatch({ type: "SET_DIVISION_LIST", payload: data });
      },
    });
    useSWR(
      () => {
        if (state.division) {
          return "/config/address/bd/districts/" + state.division;
        }
        return null;
      },
      null,
      {
        onSuccess(data, key, config) {
          dispatch({ type: "SET_DISTRICT_LIST", payload: data });
        },
      }
    );
    useSWR(
      () => {
        if (state.district) {
          return "/config/address/bd/areas/" + state.district;
        }
        return null;
      },
      null,
      {
        onSuccess(data, key, config) {
          dispatch({ type: "SET_SUB_DISTRICT_LIST", payload: data });
        },
      }
    );
    
    return {
        state,
        dispatch
    }

}