import { FC } from "react";
// import Lottie from 'react-lottie';
// import Lottie from 'react-lottie';
// import butterfly from '../../assets/lottie/butterfly.json';
import butterfly from "../../assets/lottie/loading.gif";

// import hourGlass from '../../assets/lottie/hour.json';
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: butterfly,
  // rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  // }
};

const Loading: FC<{ dontTakefullPageWidth?: boolean }> = ({
  dontTakefullPageWidth,
}) => {
  if (dontTakefullPageWidth) {
    return <img className="loading" cy-loading src={butterfly} />;
  }
  return (
    <div className="h-[100vh] flex items-center justify-center loading">
      <img cy-loading src={butterfly} />
    </div>
  );
};
export default Loading;
