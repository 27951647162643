import React from "react";
import useBoundStore from "../../../../state/store";
import { useReuqestImmutable } from "../../../hook/useRequest";
import AsyncValue from "../../../shared/AsyncValue";
import { SearchCompanyData } from "./components";
interface SelectCompanyAndPostProps {}
// Generated by https://quicktype.io

export interface ICompany {
  id: string;
  name: string;
  phone: string;
  email: string;
  company_type_id: string;
  logo: string;
  address: string;
}

const SelectCompanyAndPost: React.FC<SelectCompanyAndPostProps> = ({}) => {
  const { userConfig } = useBoundStore();
  const req = useReuqestImmutable(
    `/company/?id=${userConfig?.modules?.bnpl?.whitelisted_companies}`
  );

  const [company, setCompany] = React.useState<ICompany[]>([]);
  const handleInput = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    setCompany(company.filter((d) => d.name.toLowerCase().includes(query)));
  };

  return (
    <section>
      <AsyncValue<ICompany[], any>
        res={req}
        onSuccess={function (data: ICompany[]): JSX.Element {
          return <SearchCompanyData data={data} />;
        }}
      />
    </section>
  );
};

export default SelectCompanyAndPost;
