import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RouterItemEnum, makeRoute } from "../../../../router/path";
import { useGotoRoute } from "../../../hook/useNavigate";
import { AddressBreakdown } from "../../../shared/Address";
import AddressText from "../../../shared/Address/AddressText";

export const BNPLAddressVerifyPagerouteName = "bnpl-address-input-route";

export interface IBNPLAddressVerifyPageProps {}

const BNPLAddressVerifyPage: FC<IBNPLAddressVerifyPageProps> = ({}) => {
  const nav = useGotoRoute();
  // get search params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(Boolean(Object.fromEntries(searchParams)["type"]));
  }, [searchParams]);

  if (!Boolean(Object.fromEntries(searchParams)["type"])) {
    return (
      <AddressText
        params={{
          title: "Home Address",
          type: "home",
          module: searchParams.get("module") ?? "",
        }}
      />
    );
  }

  return (
    <AddressBreakdown
      headingText={searchParams.get("title") ?? "Home Address"}
      type={(searchParams.get("type") as any) ?? "home"}
      afterSuccessFullSend={() => {
        if (searchParams.get("type") === "home") {
          setSearchParams({
            title: "Office Address",
            type: "office",
            module: "bnpl",
          });
        } else {
          nav(makeRoute([RouterItemEnum.payLater, RouterItemEnum.OverView]));
        }
      }}
    />
  );
};
export default BNPLAddressVerifyPage;
