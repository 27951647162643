import { useLang } from "../../../../components/hooks/useLang";
import { makeRoute, RouterItemEnum } from "../../../../router/path";
import useBoundStore from "../../../../state/store";
import { RouterCreditCard } from "../../../credit/routes";
import { EKYCContext } from "../../../ekyc/types";
import { useGotoRoute } from "../../../hook/useNavigate";

export const useNavigateAfterSubmit = (route?: string) => {
  const navigate = useGotoRoute();
  const {
    ekyccontext,
    userLoanStatus,
    userConfig: usrConfig,
  } = useBoundStore();
  const { t_loan } = useLang();

  return () => {
    if (usrConfig?.ekyc.required) {
      switch (ekyccontext) {
        case EKYCContext.CreditCard:
          navigate(RouterCreditCard.CreditCardAgreement);
          break;
        case EKYCContext.BNPL:
          userLoanStatus?.downpayment !== "0" &&
            navigate(
              makeRoute([RouterItemEnum.loan, RouterItemEnum.AddWallet])
            );
          if (
            userLoanStatus?.downpayment === "0" ||
            !userLoanStatus?.downpayment
          ) {
            navigate(
              makeRoute([
                RouterItemEnum.payLater,
                RouterItemEnum.PaylaterAgreement,
              ])
            );
          }
          break;
        case EKYCContext.Cash:
          if (usrConfig && usrConfig.modules.cash.disbursment.account_input) {
            navigate(
              makeRoute([RouterItemEnum.loan, RouterItemEnum.AddWallet])
            );
          } else {
            navigate(
              makeRoute([
                RouterItemEnum.common,
                RouterItemEnum.thankYou,
                `?content=${t_loan(
                  "you will get notification once the loan is disbursed. if you don’t get any update by 48 hours please call"
                )}`,
              ])
            );
          }
          break;
        default:
          navigate(makeRoute([RouterItemEnum.common, RouterItemEnum.thankYou]));
          break;
      }
    } else {
      navigate(route ?? "/common/thanks");
    }
  };
};
