import { FC, useState } from "react";
import { ReactComponent as CorporateIcon } from "../assets/corporate.svg";
import { ReactComponent as SalaryIcon } from "../assets/salary.svg";
import { ReactComponent as WorkSpaceIcon } from "../assets/workplace.svg";

import { useTranslation } from "react-i18next";
import {
  Acknowlagment,
  SelectCompanyAndPost,
  YesOrNoRadio,
} from "./components";
export const PaylaterEligiblerouteName = "paylater-eligible-route";

export interface IPaylaterEligibleProps {}

const PaylaterEligible: FC<IPaylaterEligibleProps> = () => {
  const [currentIdx, setIndex] = useState(0);
  // const nav = useNavigate();

  const { t } = useTranslation("bnpl");

  // const { userLoanStatus } = useBoundStore();

  // useEffect(() => {
  //   if (userLoanStatus?.order?.id) {
  //     nav("/paylater/main");
  //   }
  // }, [userLoanStatus?.order?.id]);

  const increment = () => {
    if (currentIdx < WIDGETS.length - 1) setIndex(currentIdx + 1);
  };
  const WIDGETS = [
    <Acknowlagment onNextClick={increment} />,
    <YesOrNoRadio
      icon={<CorporateIcon />}
      title={t("are_you_a_corporate_executive?")}
      onNextClick={increment}
    />,
    <YesOrNoRadio
      icon={<WorkSpaceIcon />}
      title={t("is_your_workplace_in_dhaka?")}
      onNextClick={increment}
    />,
    <YesOrNoRadio
      icon={<SalaryIcon />}
      title={t("is_your_salary_above_30,000?")}
      onNextClick={increment}
    />,

    <SelectCompanyAndPost />,
  ];

  return WIDGETS[currentIdx];
};
export default PaylaterEligible;
