
import TabItem from './TabItem'

// import { ReactComponent as MessageIcon } from '../../assets/icons/envelope.svg'

import { RouterItemEnum } from '../../router/path'

import { ReactComponent as HomeIcon } from '../../assets/iconset/icons/Home.svg'
import { ReactComponent as MoreIcon } from '../../assets/iconset/icons/more.svg'
import { ReactComponent as Notification } from '../../assets/iconset/icons/notification.svg'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ReactComponent as ActivityIcons } from '../../assets/iconset/icons/Activity.svg'


// style="fill:none; stroke:#231f20; stroke-miterlimit:10; stroke-width:6px;"
const BottomNav = () => {
    const currentPath = useLocation()
    const { t } = useTranslation()



    return (
        <section className='fixed inset-x-0 bottom-0 z-10 bg-gray-100 shadow-md flex justify-around'>
            <TabItem route={RouterItemEnum.home} title={t('home')} >
                <HomeIcon className={`w-8 ${currentPath.pathname === RouterItemEnum.home ? ' stroke-kGreen-dark' : 'stroke-kGrey'} `} />

            </TabItem>
            <TabItem route={RouterItemEnum.notification} title={t('notification')} >
                <Notification className={`w-8 ${currentPath.pathname === RouterItemEnum.notification ? ' stroke-kGreen-dark' : 'stroke-kGrey'} `} />
            </TabItem>
            <TabItem route={RouterItemEnum.stateMent} title={t('activity')} >


                <ActivityIcons className={`w-8 ${currentPath.pathname === RouterItemEnum.stateMent ? ' stroke-kGreen-dark' : 'stroke-kGrey'} `} />

            </TabItem>
            <TabItem route={RouterItemEnum.More} title={t('more')} >


                <MoreIcon className={`w-8 ${currentPath.pathname === RouterItemEnum.More ? ' stroke-kGreen-dark' : 'stroke-kGrey'} `} />

            </TabItem>
            {/* <TabItem route={RouterItemEnum.notification} svgAsset={<img className='w-6 h-6' alt='notification' src={notificationImg} />} title="Notification" />
            <TabItem route={RouterItemEnum.stateMent} svgAsset={<img className='w-6 h-6' alt='statement' src={statementImg} />} title="Statement" />
            <TabItem route={RouterItemEnum.More} svgAsset={<img className='w-6 h-6' alt='More' src={moreImg} />} title="More" /> */}
        </section>
    )
}

export default BottomNav