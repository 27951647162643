import { FC } from "react";
import BtnPrimary from "../../../modules/shared/BtnPrimary";

import { useLang } from "../../hooks/useLang";
import { IModalChildProps, ModalChild } from "./ModalChild";
export interface IGotItModalProps extends IModalChildProps {
  img?: React.ReactNode;
  heading?: React.ReactNode;
  description?: React.ReactNode;
  footerBtnText?: string;
  eventProps?: string;
}

export const GotItModal: FC<IGotItModalProps> = ({
  children,
  heading,
  footerBtnText,
  description,
  eventProps,
  ...restProps
}) => {
  const { t_main } = useLang();

  return (
    <ModalChild
      Footer={
        <div>
          <BtnPrimary
            onClick={() => {
              // sendEventWithParmas(
              //   eventProps ?? "got_it",
              //   eventProps ?? "got it",
              //   eventProps ?? "got it"
              // );
            }}
            center
          >
            {footerBtnText ?? t_main("got_it")}
          </BtnPrimary>
        </div>
      }
      Body={
        <div>
          <div className="flex justify-center items-center">
            {restProps.img}
          </div>
          <p className="text-center font-medium text-gray-700 text-xl">
            {heading}
          </p>
          <div className="h-2"></div>
          <p className="text-center text-sm text-gray-700">{description}</p>
        </div>
      }
      closeButtonOnFooter
      {...restProps}
    >
      <div>{children}</div>
    </ModalChild>
  );
};
