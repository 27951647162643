import { IonItem, IonList, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRequestForMutation } from "../../../../hook/useRequest";
import { AsyncMutationRequest } from "../../../../shared/AsyncValue";
import { ICompany } from "../SelectCompanyAndPost";
interface SearchCompanyDataProps {
  data: ICompany[];
}

const SearchCompanyData: React.FC<SearchCompanyDataProps> = ({ data }) => {
  const nav = useNavigate();
  let [results, setResults] = useState([...data]);
  const { t } = useTranslation("bnpl");
  const { trigger, ...restProps } = useRequestForMutation("/user/company");
  const handleItemClick = (id: any) => {
    trigger(
      {
        postBody: {
          company_id: id,
        },
      },
      {
        onSuccess: () => {
          nav("/paylater/main");
        },
      }
    );
  };
  const handleInput = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    setResults(data.filter((d) => d.name.toLowerCase().includes(query)));
  };
  return (
    <>
      <div className="h-screen bg-gray-50 flex flex-col ">
        <div className="h-10"></div>
        <h3 className="text-center font-semibold text-lg">
          {t("_choose_your_company")}
        </h3>
        <AsyncMutationRequest<any, any>
          res={restProps}
          onSuccess={function (data: any): JSX.Element {
            return <p>{t("company_select_successfull")}</p>;
          }}
        />

        <div className="h-4"></div>
        <IonSearchbar
          debounce={100}
          color={"success"}
          onIonInput={(ev) => handleInput(ev)}
        ></IonSearchbar>

        <IonList>
          {results.map((result) => (
            <IonItem onClick={() => handleItemClick(result.id)} key={result.id}>
              {result.name}
            </IonItem>
          ))}
        </IonList>
        <div className="h-10"></div>
      </div>
    </>
  );
};

export default SearchCompanyData;
