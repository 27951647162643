import { FC, useEffect } from "react";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import useBoundStore from "../../../state/store";
import { useRequestForMutation } from "../../hook/useRequest";
import { AsyncMutationRequest } from "../AsyncValue";
import BtnPrimary from "../BtnPrimary";
import { ReactComponent as HomeIcon } from "./assets/home.svg";
import { ReactComponent as OfficeIcon } from "./assets/office.svg";
import { AddressTextField, SelectField } from "./components";
import { useAddressBreakDown } from "./hooks";
export interface IAddressBreakdownProps {
  headingText: string;
  type: "home" | "office" | "shipping";
  as?: "page" | "component";
  afterSuccessFullSend?: VoidFunction;
}

const AddressBreakdown: FC<IAddressBreakdownProps> = ({
  headingText,
  type,
  as = "page",
  afterSuccessFullSend,
}) => {
  const { state, dispatch } = useAddressBreakDown();
  const { setAddressForPaylater } = useBoundStore();

  const { trigger, ...req } = useRequestForMutation("/user/address/bd");

  useEffect(() => {}, [type]);

  const onSuccessCallback = (data: any) => {
    if (type === "shipping") {
      setAddressForPaylater(data["full_address"] ?? "");
    }
    req.reset();
    if (afterSuccessFullSend) afterSuccessFullSend();
  };

  const imageAsset = () => {
    switch (type) {
      case "home":
        return <HomeIcon />;
      case "office":
        return <OfficeIcon />;
      case "shipping":
        return <div></div>;
    }
  };

  if (as === "component")
    return (
      <AsyncMutationRequest
        res={req}
        onSuccess={(data) => {
          return <div>{JSON.stringify(data)}</div>;
        }}
        onIdle={
          <>
            <div className="mx-4">
              {type === "home" ? (
                <HomeIcon />
              ) : type === "office" ? (
                <OfficeIcon />
              ) : null}
              <SelectField
                label="Division"
                name="division"
                disabled={!Boolean(state.divisionList)}
                onChange={(e) =>
                  dispatch({ type: "SET_DIVISION", payload: e.target.value })
                }
              >
                <option disabled selected value={undefined}>
                  Select Division
                </option>
                {state.divisionList.map((division) => (
                  <option key={division.id} value={division.id}>
                    {division.name}
                  </option>
                ))}
              </SelectField>

              <SelectField
                key={
                  state.division ||
                  `division${state.divisionList.length}${state.divisionList[0]?.id}`
                }
                label="District"
                name="district"
                disabled={
                  !(Boolean(state.districtList) && Boolean(state.division))
                }
                onChange={(e) =>
                  dispatch({ type: "SET_DISTRICT", payload: e.target.value })
                }
              >
                <option disabled selected value={undefined}>
                  Select District
                </option>
                {state.districtList.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </SelectField>

              <SelectField
                key={state.district}
                label="Sub-District/Thana"
                name="sub-district"
                disabled={
                  !(Boolean(state.subDistrictList) && Boolean(state.district))
                }
                onChange={(e) =>
                  dispatch({
                    type: "SET_SUB_DISTRICT",
                    payload: e.target.value,
                  })
                }
              >
                <option disabled selected value={undefined}>
                  Select Sub-District/Thana
                </option>
                {state.subDistrictList.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </SelectField>

              <AddressTextField
                label={"Post Code"}
                name={"post-code"}
                required={false}
                onChange={(e) =>
                  dispatch({ type: "SET_POST_CODE", payload: e.target.value })
                }
              />
              <div className="my-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Full Address
                </label>
                <textarea
                  className="ftxt"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_FULL_ADDRESS",
                      payload: e.target.value,
                    });
                  }}
                  placeholder="Enter your full address"
                  rows={5}
                />
              </div>
            </div>

            <BtnPrimary
              center
              disable={!Boolean(state.subDistrict && state.fullAddress)}
              onClick={() => {
                trigger(
                  {
                    postBody: {
                      type,
                      area_id: parseInt(state.subDistrict),
                      post_code: state.postCode,
                      additional_info: state.fullAddress,
                    },
                  },
                  {
                    onSuccess: onSuccessCallback,
                  }
                );
              }}
            >
              <span className="text-sm">Next</span>
            </BtnPrimary>
          </>
        }
      />
    );

  return (
    <TitlePageLayout title={headingText} dontShowBottomNav>
      <div className="h-10"></div>
      <AsyncMutationRequest
        res={req}
        onSuccess={(data) => {
          return <div>{JSON.stringify(data)}</div>;
        }}
        onIdle={
          <>
            <div className="mx-4">
              {type === "home" ? (
                <HomeIcon />
              ) : type === "office" ? (
                <OfficeIcon />
              ) : null}
              <SelectField
                label="Division"
                name="division"
                disabled={!Boolean(state.divisionList)}
                onChange={(e) =>
                  dispatch({ type: "SET_DIVISION", payload: e.target.value })
                }
              >
                <option disabled selected value={undefined}>
                  Select Division
                </option>
                {state.divisionList.map((division) => (
                  <option key={division.id} value={division.id}>
                    {division.name}
                  </option>
                ))}
              </SelectField>

              <SelectField
                key={
                  state.division ||
                  `division${state.divisionList.length}${state.divisionList[0]?.id}`
                }
                label="District"
                name="district"
                disabled={
                  !(Boolean(state.districtList) && Boolean(state.division))
                }
                onChange={(e) =>
                  dispatch({ type: "SET_DISTRICT", payload: e.target.value })
                }
              >
                <option disabled selected value={undefined}>
                  Select District
                </option>
                {state.districtList.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </SelectField>

              <SelectField
                key={state.district}
                label="Sub-District/Thana"
                name="sub-district"
                disabled={
                  !(Boolean(state.subDistrictList) && Boolean(state.district))
                }
                onChange={(e) =>
                  dispatch({
                    type: "SET_SUB_DISTRICT",
                    payload: e.target.value,
                  })
                }
              >
                <option disabled selected value={undefined}>
                  Select Sub-District/Thana
                </option>
                {state.subDistrictList.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </SelectField>

              <AddressTextField
                label={"Post Code"}
                name={"post-code"}
                required={false}
                onChange={(e) =>
                  dispatch({ type: "SET_POST_CODE", payload: e.target.value })
                }
              />
              <div className="my-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Full Address
                </label>
                <textarea
                  className="ftxt"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_FULL_ADDRESS",
                      payload: e.target.value,
                    });
                  }}
                  placeholder="Enter your full address"
                  rows={5}
                />
              </div>
            </div>

            <BtnPrimary
              center
              disable={!Boolean(state.subDistrict && state.fullAddress)}
              onClick={() => {
                trigger(
                  {
                    postBody: {
                      type,
                      area_id: parseInt(state.subDistrict),
                      post_code: state.postCode,
                      additional_info: state.fullAddress,
                    },
                  },
                  {
                    onSuccess: onSuccessCallback,
                  }
                );
              }}
            >
              <span className="text-sm">Next</span>
            </BtnPrimary>
          </>
        }
      />
    </TitlePageLayout>
  );
};
export default AddressBreakdown;
