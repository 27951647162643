import moment from "moment";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { generateList } from "../../../../utils";
import { RepaymentInfo } from "../../pages/types";

export interface IEMIShowProps {
  emiNumber: number;
  fees: React.ReactNode;
  fristAmount: string;
  emiAmount: string;
}

const EMIShow: FC<IEMIShowProps> = ({
  emiNumber,
  fees,
  fristAmount,
  emiAmount,
}) => {
  const { t } = useTranslation("bnpl");
  const total =
    Number(fristAmount.replace("৳", "")) +
    Number(emiAmount.replace("৳", "")) * (emiNumber - 1);
  return (
    <ol className="relative border-l border-gray-200 dark:border-gray-700 mx-4">
      <ListView
        items={generateList(emiNumber, (idx) => {
          if (idx === 0) {
            return (
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                  <div className="w-3 h-3 rounded-full bg-kGreen-light" />
                </span>
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <h4 className="font-bold text-gray-800 dark:text-gray-100">
                      {t("installment_no")} {idx + 1}
                    </h4>
                    <p className="text-sm text-gray-400">{t("due_today")}</p>
                  </div>
                  <div className="flex flex-col items-end">
                    <h4 className="font-bold text-gray-800 dark:text-gray-100">
                      {fristAmount}
                    </h4>
                    {fees}
                  </div>
                </div>
              </li>
            );
          }
          return (
            <li className="mb-10 ml-6">
              <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <div className="w-3 h-3 rounded-full bg-white" />
              </span>
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <h4 className="font-bold text-gray-800 dark:text-gray-100">
                    {t("installment_no")} {idx + 1}
                  </h4>

                  <p className="text-sm text-gray-400">
                    {t("_due_on")}
                    {moment()
                      .add(idx + 1, "months")
                      .format("LL")}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p>{emiAmount}</p>
                </div>
              </div>
            </li>
          );
        })}
      />

      <li className="border-t-2 mx-1 p-2">
        <div className="flex justify-between">
          <p className="font-bold">{t("total_cost")}</p>
          <p>৳{total}</p>
        </div>
      </li>
    </ol>
  );
};

interface EMIShowSkeletonProps {
  repaymentInfo: RepaymentInfo;
  chargeBreakDown: JSX.Element;
}

export const EMIShowSkeleton: FC<EMIShowSkeletonProps> = ({
  repaymentInfo,
  chargeBreakDown,
}) => {
  const { t } = useTranslation("bnpl");
  return (
    <div className="mx-4">
      <ol className="relative border-l border-gray-200 dark:border-gray-700 mx-2">
        {repaymentInfo.schedule.map(({ installment_no, amount }, index) => {
          return (
            <li className="mb-10 ml-6">
              <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <div className="w-3 h-3 rounded-full bg-white" />
              </span>
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <h4 className="font-bold text-gray-800 dark:text-gray-100">
                    {t("installment_no")} {installment_no}
                  </h4>
                  <p className="text-sm text-gray-400">
                    {t("_due_on")}{" "}
                    {moment()
                      .add(index + 1, "months")
                      .format("LL")}
                  </p>
                </div>
                <div className="flex flex-col justify-end items-end">
                  <p className="font-bold">৳{amount}</p>{" "}
                  {index === 0 && chargeBreakDown}
                </div>
              </div>
            </li>
          );
        })}
      </ol>
      <li className="border-t-2 mx-1 p-2 list-none">
        <div className="flex justify-between">
          <p className="font-bold">{t("total_cost")}</p>
          <p className="text-xl font-bold">৳{repaymentInfo.amount}</p>
        </div>
      </li>
    </div>
  );
};

interface ListViewProps {
  items: JSX.Element[];
}

export const ListView: React.FC<ListViewProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </>
  );
};
export default EMIShow;
