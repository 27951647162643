import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RouterItemEnum } from "../../../router/path";
import { HowToApplyrouteName } from "../HowtoApply";
import PaymentLoan, { PaymentLoanSchedulerouteName } from "../pages/Payment";
const HowtoApply = lazy(() => import("../HowtoApply/HowToApply"));
const Disbursed = lazy(() => import("../../shared/Disbursed"));
const AddPhonePage = lazy(() => import("../Accounts/pages/AddPhonePage"));
const WalletListPage = lazy(() => import("../Accounts/pages/WalletListPage"));
const LoanMain = lazy(() => import("../pages/HomeFragment"));
const LoanAcceptPage = lazy(() => import("../pages/LoanAccept"));
const LoanHistoryDetails = lazy(() => import("../pages/LoanHistoryDetails"));
const LoanHistoryList = lazy(() => import("../pages/LoanHistoryList"));

export const loanRouters: RouteObject[] | undefined = [
  {
    path: RouterItemEnum.loan,
    children: [
      {
        index: true,
        element: <LoanMain />,
      },

      {
        path: RouterItemEnum.LoanAccept,
        element: <LoanAcceptPage />,
      },
      {
        path: RouterItemEnum.AddWallet,
        element: <WalletListPage />,
      },
      {
        path: RouterItemEnum.Disbursed,
        element: <Disbursed />,
      },
      {
        path: RouterItemEnum.History,
        element: <LoanHistoryList />,
      },
      {
        path: RouterItemEnum.History + "/:id",
        element: <LoanHistoryDetails />,
      },
      {
        path: RouterItemEnum.addPhone + "/:walletId",
        element: <AddPhonePage />,
      },
      {
        path: HowToApplyrouteName,
        element: <HowtoApply />,
      },
      {
        path: PaymentLoanSchedulerouteName,
        element: <PaymentLoan />,
      },
    ],
  },
];
