import { IonLabel, IonRadio, IonRadioGroup, useIonAlert } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FullPageContent, { FullPageContentProps } from "./FullPageContent";

interface YesOrNoRadioProps extends Omit<FullPageContentProps, "onSkipClick"> {
  title: string;
}
// AP123ssdTech
const YesOrNoRadio: React.FC<YesOrNoRadioProps> = ({
  title,
  onNextClick,
  ...restProps
}) => {
  const [value, setValue] = React.useState<boolean>();
  const nav = useNavigate();
  const { t } = useTranslation(["bnpl"]);

  const handleClick = () => {
    if (value) {
      onNextClick?.();
      toggle();
    } else {
      showNotEligibleAlert();
    }
  };
  const [present] = useIonAlert();

  const toggle = () => setValue(undefined);

  const showNotEligibleAlert = () => {
    present({
      header: `${t("bnpl:not_eligible")}`,
      message: `${t("bnpl:you_are_not_eligible_for_paylater")}`,
      buttons: [
        {
          text: `${t("bnpl:ok")}`,
          handler: () => {
            console.log("Confirm Okay");
          },
        },
      ],
    });
  };
  return (
    <FullPageContent
      {...restProps}
      onNextClick={handleClick}
      onSkipClick={() => nav(-1)}
    >
      <div className="text-center">
        <IonLabel>{title}</IonLabel>
        <div className="h-4" />
        <IonRadioGroup
          value={value}
          className="space-x-4"
          allowEmptySelection
          onIonChange={(e) => setValue(e.detail.value)}
        >
          <IonRadio color={"success"} labelPlacement="end" value={true}>
            {t("bnpl:yes")}
          </IonRadio>
          <IonRadio color={"success"} labelPlacement="end" value={false}>
            {t("bnpl:no")}
          </IonRadio>
        </IonRadioGroup>
      </div>
    </FullPageContent>
  );
};

export default YesOrNoRadio;
