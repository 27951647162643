import { Modal } from "flowbite-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserEventAnalytics } from "../../../analytics/utils";
import { useLang } from "../../../components/hooks/useLang";
import BtnPrimary from "../../../modules/shared/BtnPrimary";
import { RouterItemEnum, makeRoute } from "../../../router/path";
import useBoundStore from "../../../state/store";
import { formateDateFromUnix } from "../../../utils/en2bn";

const Rescore: FC = () => {
  const { useStatus, primaryColor } = useBoundStore();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { convertToBanglaNumber } = useLang();
  const [showModal, setShowModal] = useState(false);
  const sendEvent = useUserEventAnalytics();

  const handleRescore = () => {
    if (useStatus?.can_rescore) {
      navigator(makeRoute([RouterItemEnum.Score, "new"]));

      sendEvent({
        action: "rescore_start",
      });
    } else {
      setShowModal(true);
      // toast.error(`You can rescore at ${formateDateFromUnix(useStatus!.rescore_at)}`)
    }
  };
  return (
    <div className="flex-col">
      <div
        className="flex flex-col"
        onClick={() => navigator("/" + RouterItemEnum.MyScore)}
      >
        <p className="text-sm font-bold text-gray-500">
          {useStatus?.score_comment}
        </p>
        <p className="text-4xl font-medium text-gray-700">
          {useStatus?.score &&
            convertToBanglaNumber(useStatus?.score.toString() ?? "0")}
        </p>
      </div>
      <div className="h-2"></div>
      <div
        style={{
          backgroundColor: primaryColor,
        }}
        onClick={handleRescore}
        className="inline-bloc w-full h-full rounded-b-full px-3 py-1"
      >
        <p className="text-white text-center text-sm">{t("rescore")}</p>
      </div>

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        position="center"
      >
        <Modal.Body>
          <div className="flex items-center justify-center">
            <svg
              width="221"
              height="209"
              viewBox="0 0 221 209"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_322_5149)">
                <path
                  d="M110.498 209.005C171.524 209.005 220.995 180.517 220.995 145.375C220.995 110.234 171.524 81.7456 110.498 81.7456C49.4714 81.7456 0 110.234 0 145.375C0 180.517 49.4714 209.005 110.498 209.005Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M153.984 124.466L191.198 145.893C192.153 146.441 193.33 146.441 194.285 145.893L214.993 133.961C215.606 133.606 215.606 132.722 214.993 132.367L177.784 110.999C176.829 110.451 175.656 110.451 174.706 110.999L153.989 122.872C153.376 123.228 153.371 124.111 153.989 124.466H153.984Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M145.058 127.506L55.5348 179.184C54.0505 180.042 52.2247 180.047 50.7304 179.208L27.4553 166.082C26.3914 165.48 26.3865 163.955 27.4454 163.348L116.83 111.868C118.309 111.019 120.125 111.004 121.615 111.838L145.038 124.925C146.048 125.488 146.058 126.934 145.058 127.511V127.506Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M192.163 162.351C196.893 162.351 200.727 160.142 200.727 157.416C200.727 154.691 196.893 152.481 192.163 152.481C187.432 152.481 183.598 154.691 183.598 157.416C183.598 160.142 187.432 162.351 192.163 162.351Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M192.441 79.6581L192.451 144.788L213.604 132.599V67.5332L192.441 79.6581Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M156.523 124.101L192.45 144.788V79.7221L156.533 59.0945L156.523 124.101Z"
                  fill="#EBEBEB"
                />
                <path
                  d="M156.523 59.0353L192.45 79.7221L213.603 67.5331L177.686 46.9055L156.523 59.0353Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M160.733 82.8508L188.036 98.5683C188.561 98.8693 189.214 98.4942 189.214 97.8922V84.0944C189.214 83.4035 188.843 82.7669 188.244 82.4215L160.951 66.7485C160.427 66.4474 159.773 66.8225 159.773 67.4245V81.1779C159.769 81.8688 160.135 82.5054 160.733 82.8508V82.8508Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M176.893 79.3866C176.799 79.3866 176.71 79.3619 176.626 79.3126L171.807 76.5441C171.55 76.3961 171.461 76.0655 171.609 75.8088C171.757 75.5522 172.089 75.4634 172.346 75.6115L177.166 78.3799C177.423 78.5279 177.512 78.8586 177.363 79.1152C177.264 79.2879 177.081 79.3866 176.893 79.3866Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M160.733 102.131L188.036 117.849C188.561 118.15 189.214 117.775 189.214 117.172V103.375C189.214 102.684 188.843 102.047 188.244 101.702L160.951 86.0287C160.427 85.7277 159.773 86.1028 159.773 86.7048V100.458C159.769 101.149 160.135 101.786 160.733 102.131V102.131Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M176.893 98.6718C176.799 98.6718 176.71 98.6471 176.626 98.5977L171.807 95.8293C171.55 95.6813 171.461 95.3506 171.609 95.094C171.757 94.8374 172.089 94.7486 172.346 94.8966L177.166 97.6651C177.423 97.8131 177.512 98.1437 177.363 98.4003C177.264 98.5731 177.081 98.6718 176.893 98.6718Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M160.733 121.412L188.036 137.129C188.561 137.43 189.214 137.055 189.214 136.453V122.655C189.214 121.964 188.843 121.328 188.244 120.982L160.951 105.309C160.427 105.008 159.773 105.383 159.773 105.985V119.739C159.769 120.43 160.135 121.066 160.733 121.412V121.412Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M176.893 117.952C176.799 117.952 176.71 117.928 176.626 117.878L171.807 115.11C171.55 114.962 171.461 114.631 171.609 114.375C171.757 114.118 172.089 114.029 172.346 114.177L177.166 116.946C177.423 117.094 177.512 117.424 177.363 117.681C177.264 117.854 177.081 117.952 176.893 117.952Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M192.668 144.842C192.668 144.842 187.403 139.715 190.402 131.128C192.079 126.317 197.646 122.779 200.812 122.448C200.812 122.448 199.095 124.422 198.338 126.613L194.35 129.258L198.017 128.113L197.235 131.242C197.235 131.242 193.826 133.3 193.316 133.749L196.992 132.791C196.992 132.791 196.71 141.654 192.668 144.842V144.842Z"
                  fill="#BA68C8"
                />
                <path
                  d="M192.668 144.842C192.668 144.842 187.403 139.715 190.402 131.128C192.079 126.317 197.646 122.779 200.812 122.448C200.812 122.448 199.095 124.422 198.338 126.613L194.35 129.258L198.017 128.113L197.235 131.242C197.235 131.242 193.826 133.3 193.316 133.749L196.992 132.791C196.992 132.791 196.71 141.654 192.668 144.842V144.842Z"
                  fill="#006766"
                />
                <path
                  d="M192.668 144.842C192.668 144.842 189.941 134.114 193.974 127.857C193.974 127.857 189.249 133.379 192.668 144.842Z"
                  fill="white"
                />
                <path
                  d="M198.694 146.293L192.252 146.214H192.217H192.183L185.74 146.293C185.74 146.293 185.671 154.204 188.11 157.426C188.783 158.28 190.367 158.882 192.212 158.882C194.058 158.882 195.641 158.28 196.314 157.421C198.754 154.199 198.684 146.293 198.684 146.293H198.694Z"
                  fill="#37474F"
                />
                <path
                  d="M198.694 146.293C198.694 148.208 195.794 149.757 192.222 149.757C188.649 149.757 185.75 148.208 185.75 146.293C185.75 144.378 188.649 142.829 192.222 142.829C195.794 142.829 198.694 144.378 198.694 146.293Z"
                  fill="#455A64"
                />
                <path
                  d="M192.223 145.375C194.449 145.375 196.334 146.086 197.002 147.073C197.166 146.826 197.26 146.565 197.26 146.293C197.26 144.926 195.003 143.816 192.223 143.816C189.442 143.816 187.186 144.926 187.186 146.293C187.186 146.565 187.28 146.826 187.443 147.073C188.106 146.086 189.991 145.375 192.223 145.375Z"
                  fill="#263238"
                />
                <path
                  d="M192.223 145.375C189.997 145.375 188.111 146.086 187.443 147.073C188.106 148.06 189.992 148.77 192.223 148.77C194.455 148.77 196.335 148.06 197.003 147.073C196.34 146.086 194.455 145.375 192.223 145.375Z"
                  fill="#E0E0E0"
                />
                <path
                  d="M192.296 147.502C192.296 147.502 189.803 147.404 187.66 144.102C186.779 142.74 186.354 142.025 186.354 142.025L189.827 141.768L185.933 140.796L184.093 137.988L187.329 137.746C187.329 137.746 184.919 136.902 183.202 136.892C183.202 136.892 180.218 131.637 179.348 130.892C179.348 130.892 186.225 130.946 191.129 136.73C196.032 142.513 193.024 147.65 192.296 147.502V147.502Z"
                  fill="#7FB9AF"
                />
                <path
                  d="M192.297 147.502C192.297 147.502 193.856 138.131 183.9 132.49C183.9 132.49 194.237 137.44 192.297 147.502Z"
                  fill="white"
                />
                <path
                  d="M192.296 147.502C192.296 147.502 194.557 147.65 196.799 144.867C197.724 143.722 198.174 143.115 198.174 143.115L195.062 142.557L198.669 142.049L200.594 139.69L197.699 139.172C197.699 139.172 199.95 138.639 201.504 138.792C201.504 138.792 204.691 134.331 205.547 133.739C205.547 133.739 199.337 133.142 194.369 137.899C189.401 142.656 191.628 147.576 192.296 147.507V147.502Z"
                  fill="#BA68C8"
                />
                <path
                  d="M192.296 147.502C192.296 147.502 194.557 147.65 196.799 144.867C197.724 143.722 198.174 143.115 198.174 143.115L195.062 142.557L198.669 142.049L200.594 139.69L197.699 139.172C197.699 139.172 199.95 138.639 201.504 138.792C201.504 138.792 204.691 134.331 205.547 133.739C205.547 133.739 199.337 133.142 194.369 137.899C189.401 142.656 191.628 147.576 192.296 147.507V147.502Z"
                  fill="#7FB9AF"
                />
                <path
                  d="M192.296 147.502C192.296 147.502 191.772 138.901 201.291 134.746C201.291 134.746 191.499 138.244 192.296 147.502Z"
                  fill="white"
                />
                <path
                  d="M114.425 38.7632L112.174 37.8503C112.1 37.8157 112.025 37.7861 111.946 37.7565L111.922 37.7466C111.145 37.4703 110.244 37.5048 109.428 37.9736L32.1904 82.4464C28.8852 84.3463 26.8516 87.8648 26.8516 91.6696V158.768C26.8516 161.892 30.2459 163.846 32.9573 162.287L110.289 117.76C112.535 116.467 113.921 114.074 113.921 111.488V40.5644C113.921 40.0265 113.782 39.538 113.545 39.1136L114.42 38.7632H114.425Z"
                  fill="#006766"
                />
                <path
                  opacity="0.3"
                  d="M114.425 38.7632L112.174 37.8503C112.1 37.8157 112.025 37.7861 111.946 37.7565L111.922 37.7466C111.145 37.4703 110.244 37.5048 109.428 37.9736L32.1904 82.4464C28.8852 84.3463 26.8516 87.8648 26.8516 91.6696V158.768C26.8516 161.892 30.2459 163.846 32.9573 162.287L110.289 117.76C112.535 116.467 113.921 114.074 113.921 111.488V40.5644C113.921 40.0265 113.782 39.538 113.545 39.1136L114.42 38.7632H114.425Z"
                  fill="black"
                />
                <path
                  d="M117.038 41.2849V112.208C117.038 114.794 115.657 117.183 113.411 118.48L36.0745 163.007C33.363 164.572 29.9688 162.618 29.9688 159.489V92.39C29.9688 90.5197 30.4635 88.7185 31.3542 87.1443C31.3542 87.1344 31.3641 87.1295 31.369 87.1196C32.0419 85.9254 33.0662 84.968 34.2537 84.2821L114.123 38.2993C114.707 38.3141 115.266 38.5016 115.736 38.8273C116.503 39.3455 117.038 40.2239 117.038 41.2849Z"
                  fill="#006766"
                />
                <path
                  opacity="0.1"
                  d="M117.038 41.2849V112.208C117.038 114.794 115.657 117.183 113.411 118.48L36.0745 163.007C33.363 164.572 29.9688 162.618 29.9688 159.489V92.39C29.9688 90.5197 30.4635 88.7185 31.3542 87.1443C31.3542 87.1344 31.3641 87.1295 31.369 87.1196C32.0419 85.9254 33.0662 84.968 34.2537 84.2821L114.123 38.2993C114.707 38.3141 115.266 38.5016 115.736 38.8273C116.503 39.3455 117.038 40.2239 117.038 41.2849Z"
                  fill="black"
                />
                <path
                  d="M113.663 118.683L36.3311 163.21C35.0249 163.965 33.5603 163.896 32.3926 163.274L78.547 93.9641L117.294 112.406C117.294 114.991 115.909 117.385 113.663 118.678V118.683Z"
                  fill="#006766"
                />
                <path
                  opacity="0.2"
                  d="M113.663 118.683L36.3311 163.21C35.0249 163.965 33.5603 163.896 32.3926 163.274L78.547 93.9641L117.294 112.406C117.294 114.991 115.909 117.385 113.663 118.678V118.683Z"
                  fill="black"
                />
                <path
                  d="M117.038 37.9785L85.4994 103.76C82.877 109.233 76.1379 111.315 70.8733 108.285L32.6504 86.3003C33.3035 85.1456 34.2535 84.1833 35.4064 83.522L115.425 37.4504C116.008 37.4652 116.568 37.6528 117.038 37.9785Z"
                  fill="#006766"
                />
                <path
                  d="M38.2227 66.1119L60.8744 53.0642V36.8977L44.4125 45.9433C40.5927 48.0406 38.2227 52.0427 38.2227 56.3952V66.1168V66.1119Z"
                  fill="#006766"
                />
                <path
                  opacity="0.5"
                  d="M38.2227 66.5165L60.8744 53.4688V37.3022L44.4125 46.3478C40.5927 48.4451 38.2227 52.4472 38.2227 56.7998V66.5214V66.5165Z"
                  fill="white"
                />
                <path
                  d="M131.828 3.48894L129.774 2.30951C124.411 -0.779693 117.8 -0.764888 112.452 2.34406L47.6732 39.9919C41.8198 43.392 38.2227 49.6395 38.2227 56.3903C38.2227 48.9091 46.367 44.2556 52.8389 48.0357L55.5553 49.6197C61.2999 52.9754 64.8278 59.1144 64.8278 65.7517V161.754L60.4142 172.936L68.707 167.903L135.415 129.298C140.23 126.509 143.193 121.377 143.193 115.825V23.1246C143.193 15.0266 138.864 7.54043 131.828 3.48894V3.48894Z"
                  fill="#006766"
                />
                <path
                  opacity="0.7"
                  d="M131.828 3.48894L129.774 2.30951C124.411 -0.779693 117.8 -0.764888 112.452 2.34406L47.6732 39.9919C41.8198 43.392 38.2227 49.6395 38.2227 56.3903C38.2227 48.9091 46.367 44.2556 52.8389 48.0357L55.5553 49.6197C61.2999 52.9754 64.8278 59.1144 64.8278 65.7517V161.754L60.4142 172.936L68.707 167.903L135.415 129.298C140.23 126.509 143.193 121.377 143.193 115.825V23.1246C143.193 15.0266 138.864 7.54043 131.828 3.48894V3.48894Z"
                  fill="white"
                />
                <path
                  d="M40.9092 164.216C40.9092 164.216 45.5554 170.997 53.0218 173.237C61.0523 175.65 64.8227 170.341 64.8227 163.299V150.562L40.9043 164.216H40.9092Z"
                  fill="#006766"
                />
                <path
                  opacity="0.5"
                  d="M40.9092 164.216C40.9092 164.216 45.5554 170.997 53.0218 173.237C61.0523 175.65 64.8227 170.341 64.8227 163.299V150.562L40.9043 164.216H40.9092Z"
                  fill="white"
                />
                <path
                  d="M79.2616 96.0813C79.0093 96.0813 78.7619 95.948 78.6283 95.7161C78.4254 95.3657 78.5441 94.9216 78.8954 94.7192L128.39 66.2156C128.741 66.0133 129.186 66.1318 129.389 66.4821C129.592 66.8325 129.473 67.2766 129.122 67.479L79.6277 95.9826C79.5139 96.0467 79.3853 96.0813 79.2616 96.0813V96.0813Z"
                  fill="white"
                />
                <path
                  d="M79.2616 107.012C79.0093 107.012 78.7619 106.879 78.6283 106.647C78.4254 106.296 78.5441 105.852 78.8954 105.65L128.39 77.1463C128.741 76.944 129.186 77.0624 129.389 77.4128C129.592 77.7632 129.473 78.2073 129.122 78.4096L79.6277 106.913C79.5139 106.977 79.3853 107.012 79.2616 107.012V107.012Z"
                  fill="white"
                />
                <path
                  d="M79.2616 117.938C79.0093 117.938 78.7619 117.804 78.6283 117.573C78.4254 117.222 78.5441 116.778 78.8954 116.576L128.39 88.0721C128.741 87.8698 129.186 87.9882 129.389 88.3386C129.592 88.6889 129.473 89.1331 129.122 89.3354L79.6277 117.839C79.5139 117.903 79.3853 117.938 79.2616 117.938V117.938Z"
                  fill="white"
                />
                <path
                  d="M79.2616 128.863C79.0093 128.863 78.7619 128.73 78.6283 128.498C78.4254 128.148 78.5441 127.704 78.8954 127.501L128.39 98.9976C128.741 98.7953 129.186 98.9137 129.389 99.2641C129.592 99.6145 129.473 100.059 129.122 100.261L79.6277 128.765C79.5139 128.829 79.3853 128.863 79.2616 128.863V128.863Z"
                  fill="white"
                />
                <path
                  d="M79.2616 139.789C79.0093 139.789 78.7619 139.656 78.6283 139.424C78.4254 139.073 78.5441 138.629 78.8954 138.427L128.39 109.923C128.741 109.721 129.186 109.84 129.389 110.19C129.592 110.54 129.473 110.984 129.122 111.187L79.6277 139.69C79.5139 139.754 79.3853 139.789 79.2616 139.789V139.789Z"
                  fill="white"
                />
                <path
                  d="M111.754 132.002C111.501 132.002 111.254 131.869 111.12 131.637C110.918 131.286 111.036 130.842 111.388 130.64L128.389 120.849C128.74 120.647 129.185 120.765 129.388 121.116C129.591 121.466 129.472 121.91 129.121 122.113L112.12 131.903C112.006 131.967 111.877 132.002 111.754 132.002V132.002Z"
                  fill="white"
                />
                <path
                  d="M113.718 54.9444L99.4629 63.156V56.1485C99.4629 51.2779 102.432 45.6176 106.098 43.5055L107.088 42.938C110.749 40.8259 113.723 43.0663 113.723 47.9419V54.9494L113.718 54.9444Z"
                  fill="#006766"
                />
                <path
                  d="M117.039 57.2688L96.1387 69.2999L99.4587 61.6361L113.719 53.4246L117.039 57.2688Z"
                  fill="#006766"
                />
                <path
                  d="M106.589 45.5337C105.97 45.889 105.471 45.6028 105.471 44.8921V42.1583C105.471 41.4476 105.97 40.584 106.589 40.2287C107.207 39.8734 107.707 40.1596 107.707 40.8703V43.6042C107.707 44.3148 107.207 45.1784 106.589 45.5337Z"
                  fill="#006766"
                />
                <path
                  d="M104.213 65.6826C104.272 67.0989 105.311 67.6566 106.588 66.9213C107.864 66.186 108.894 64.4341 108.958 62.9487L104.213 65.6826Z"
                  fill="#006766"
                />
                <path
                  d="M164.627 46.3836C170.22 45.067 173.684 39.4776 172.364 33.8992C171.043 28.3209 165.439 24.8661 159.846 26.1827C154.253 27.4993 150.789 33.0888 152.109 38.6671C153.429 44.2454 159.033 47.7002 164.627 46.3836Z"
                  fill="#006766"
                />
                <path
                  d="M160.382 44.3445C160.382 44.3445 161.312 47.6409 157.547 50.7005C157.547 50.7005 163.346 50.1182 163.658 44.6208L160.382 44.3445V44.3445Z"
                  fill="#006766"
                />
                <path
                  d="M161.03 35.3234H163.266L163.825 40.6728H160.471L161.03 35.3234ZM162.148 31.8789C163.118 31.8789 163.786 32.55 163.786 33.3643C163.786 34.1785 163.113 34.825 162.148 34.825C161.183 34.825 160.51 34.1933 160.51 33.3643C160.51 32.5352 161.183 31.8789 162.148 31.8789Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_322_5149">
                  <rect width="221" height="209" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="h-6"></div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl text-gray-700">Hi There !</p>
            <p>
              {" "}
              You can rescore from{" "}
              {formateDateFromUnix(useStatus?.rescore_at ?? "")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center justify-center mx-auto">
            <BtnPrimary
              center
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              Got it
            </BtnPrimary>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Rescore;
