import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import TitlePageLayout from "../../../../components/Layouts/TitlePageLayout";
import { RouterItemEnum } from "../../../../router/path";
import AsyncValue from "../../../shared/AsyncValue";
import NoItemFound from "../../../shared/NoItemFound";
import { PaymentItem } from "./components";
import { IScheduleDetails } from "./types";
export const PaymentSchedulerouteName = "payment-schedule";

export interface IPaymentScheduleProps {}
export function getDaysLeft(date: string): number {
  const currentDate = moment();
  const targetDate = moment(date, "YYYY-MM-DD");

  const duration = moment.duration(targetDate.diff(currentDate));
  const daysLeft = duration.asDays();

  return Math.ceil(daysLeft);
}
function filterKeysByCondition<T>(
  record: Record<string, T>,
  condition: (value: T) => boolean
): string[] {
  const filteredKeys: string[] = [];

  for (const [key, value] of Object.entries(record)) {
    if (condition(value)) {
      filteredKeys.push(key);
    }
  }

  return filteredKeys;
}
const PaymentSchedule: FC<IPaymentScheduleProps> = () => {
  // const { setPaymentSchedule, userConfig } = useBoundStore();
  const { t } = useTranslation("bnpl");
  const paymentScheduleRes = useSWR<IScheduleDetails>("/loan/repay/schedules", {
    onSuccess(data) {
      // setPaymentSchedule(data);
    },
  });

  return (
    <TitlePageLayout title={t("my_payments")} dontShowBottomNav>
      <div className="h-4" />
      <AsyncValue<IScheduleDetails, any>
        res={paymentScheduleRes}
        noItemMsg={
          <p className="text-center">{t("no_payment_schedules_found")}</p>
        }
        onSuccess={function (data: IScheduleDetails): JSX.Element {
          if (data?.schedule?.length === 0 || !data?.schedule) {
            return (
              <NoItemFound
                message={
                  <p className="text-center">
                    {t("no_upcoming_payment_schedule")}
                  </p>
                }
              />
            );
          }
          const tenore = +data.tenor;

          const currentPay = data.schedule.findIndex(
            (item) => item.allow_pay || item.status === "pending"
          );

          const otherPay = data.schedule.filter(
            (item) => !item.allow_pay && item.status !== "pending"
          );
          const nonZeroFees = filterKeysByCondition(
            data.charges.fees,
            (value) => parseInt(value) > 0
          );

          return (
            <>
              {currentPay >= 0 ? (
                <PaymentItem
                  amount={data.schedule[currentPay].amount}
                  installmentNo={data.schedule[currentPay].installment_no}
                  timeSince={
                    "Due on " +
                    moment(data.schedule[currentPay].date).format("MMM D ,YYYY")
                  }
                  action={
                    data.schedule[currentPay].status === "pending" ? (
                      <p className="bg-kAmber h-8 text-white px-3 py-1 rounded-lg">
                        {t("pending")}
                      </p>
                    ) : (
                      <Link
                        to={`${RouterItemEnum.Account}/${RouterItemEnum.AddWallet}`}
                        className={`bg-kGreen-light h-8 text-white px-3 py-1 rounded-lg`}
                      >
                        {t("_pay_now")}
                      </Link>
                    )
                  }
                  footerTxt={
                    data.schedule[currentPay].installment_no === "1" ? (
                      <p className="text-sm text-gray-500">
                        {t("_1st_installment")} (৳ {data.monthly_emi}){" "}
                        {nonZeroFees.map((key) => {
                          return ` + ${t(key as any)} (৳ ${
                            data.charges.fees[key]
                          })`;
                        })}
                      </p>
                    ) : undefined
                  }
                />
              ) : (
                <></>
              )}
              {currentPay >= 0 ? (
                <div className="mx-4 border-b-2 bg-kGreen-light" />
              ) : (
                <></>
              )}

              <p className="pl-4 text-xl py-2">{t("upcoming_payments")}</p>
              {currentPay >= 0 ? (
                <PaymentItem
                  amount={data.schedule[currentPay].amount}
                  installmentNo={data.schedule[currentPay].installment_no}
                  timeSince={
                    "Due on " +
                    moment(data.schedule[currentPay].date).format("MMM D ,YYYY")
                  }
                  action={
                    data.schedule[currentPay].status === "pending" ? (
                      <p className="bg-kAmber h-8 text-white px-3 py-1 rounded-lg">
                        {t("pending")}
                      </p>
                    ) : (
                      <Link
                        to={`${RouterItemEnum.Account}/${RouterItemEnum.AddWallet}`}
                        className={`bg-kGreen-light h-8 text-white px-3 py-1 rounded-lg`}
                      >
                        {t("_pay_now")}
                      </Link>
                    )
                  }
                  footerTxt={
                    data.schedule[currentPay].installment_no === "1" ? (
                      <p className="text-sm text-gray-500">
                        {t("_1st_installment")} (৳ {data.monthly_emi}){" "}
                        {nonZeroFees.map((key) => {
                          return ` + ${t(key as any)} (৳ ${
                            data.charges.fees[key]
                          })`;
                        })}
                      </p>
                    ) : undefined
                  }
                />
              ) : (
                <></>
              )}

              {otherPay.length > 0 ? (
                otherPay.map((item, index) => {
                  return (
                    <PaymentItem
                      key={index}
                      amount={item.amount}
                      installmentNo={item.installment_no}
                      action={
                        item.status === "due" ? (
                          <p className="text-sm">
                            {moment(item.date).format("MMM D ,YYYY")}
                          </p>
                        ) : item.status === "paid" ? (
                          <p className="px-4 py-1 bg-kGreen-light/60 text-white rounded-lg">
                            {t("_paid")}
                          </p>
                        ) : (
                          <></>
                        )
                      }
                      timeSince={
                        item.status === "due"
                          ? `${getDaysLeft(item.date)} days left`
                          : Boolean(item.paid_at)
                          ? `Paid on ${moment(item.paid_at).format(
                              "MMM D ,YYYY"
                            )}`
                          : undefined
                      }
                      footerTxt={
                        item.installment_no === "1" ? (
                          <p className="text-sm text-gray-500">
                            {t("1st_installment")} (৳ {data.monthly_emi}){" "}
                            {nonZeroFees.map((key) => {
                              return ` + ${t(key as any)} (৳ ${
                                data.charges.fees[key]
                              })`;
                            })}
                          </p>
                        ) : undefined
                      }
                    />
                  );
                })
              ) : (
                <NoItemFound
                  message={
                    <p className="text-center">
                      {t("no_upcoming_payment_sechudle")}
                    </p>
                  }
                />
              )}
              {/* <div className="fixed bottom-10 left-0 w-full">
                <div className="flex justify-center">
                  <Link
                    to={"/home"}
                    className="bg-kGreen-light  text-white px-3 py-1 rounded-lg"
                  >
                    Back to Home
                  </Link>
                </div>
              </div> */}
            </>
          );
        }}
        // bottom fix button to go home
      />
    </TitlePageLayout>
  );
};
export default PaymentSchedule;
