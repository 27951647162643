import { FormikHelpers, FormikValues } from "formik";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { swrMainFetcher } from "../../../../client/axios";
import TitlePageLayout from "../../../../components/Layouts/TitlePageLayout";
import useBoundStore from "../../../../state/store";
import { IDocuments } from "../../../credit";
import { useAutoReqAndCacheResponse } from "../../../hook/useClient";
import AsyncValue from "../../../shared/AsyncValue";
import { BNPLFaceUploadrouteName } from "../FaceUpload";
import { BaseDocumentUploadForm } from "./components";

export const BNPLDocumentUploadrouteName = "upload-document-route";

export interface IBNPLDocumentUploadProps {}

const BNPLDocumentUpload: FC<IBNPLDocumentUploadProps> = ({}) => {
  const { userConfig, userInfo } = useBoundStore();
  const { t } = useTranslation("bnpl");
  const nav = useNavigate();

  const fetchSlugs = useAutoReqAndCacheResponse(
    `/user/assets/enlisted?slugs=${
      userConfig?.modules?.bnpl?.document_slugs ?? ""
    }`
  );
  return (
    <TitlePageLayout title={t("upload_your_documents")} dontShowBottomNav>
      <AsyncValue<IDocuments[], any>
        res={fetchSlugs}
        onSuccess={function (data: IDocuments[]): JSX.Element {
          return (
            <BaseDocumentUploadForm
              fields={data}
              initialValues={{} as Record<string, any>}
              onSubmit={function (
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> {
                const formData = new FormData();
                data.forEach((e) => {
                  formData.append(e.slug, values[e.slug]);
                });
                const uploadTask = swrMainFetcher<any>(
                  "/user/assets",
                  userInfo!.token,
                  {
                    postBody: formData,
                    isMultipart: true,
                  }
                ).then((res) => {
                  nav(`/paylater/${BNPLFaceUploadrouteName}`);
                });

                toast.promise(uploadTask, {
                  loading: "Uploading...",
                  success: "Uploaded successfully",
                  error: "Failed to upload",
                });
              }}
            />
          );
        }}
      />
    </TitlePageLayout>
  );
};
export default BNPLDocumentUpload;
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODcxNzgwNzAsImlzcyI6IkRhbmFVc2VyIiwiZXhwIjoxNjg4NDc0MDcwLCJpZCI6IjI0NTY2In0.1_uces7l3mQy7tIliJ_4KAOTVfaa7MqkQdLPBtGNPEs
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODcxNzgwNzAsImlzcyI6IkRhbmFVc2VyIiwiZXhwIjoxNjg4NDc0MDcwLCJpZCI6IjI0NTY2In0.1_uces7l3mQy7tIliJ_4KAOTVfaa7MqkQdLPBtGNPEs
