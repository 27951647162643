import React, { ComponentProps } from "react";
interface AddressTextFieldProps extends ComponentProps<"input"> {
  label: string;
}

const AddressTextField: React.FC<AddressTextFieldProps> = ({
  name,
  label,
  placeholder,

  required = true,
  ...restProps
}) => {
  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label} {<span className="text-red-500">*</span>}
      </label>
      <input
        name={name}
        id={name}
        required={required}
        type="text"
        className="ftxt"
        placeholder={placeholder}
        {...restProps}
      />
    </>
  );
};

export default AddressTextField;
