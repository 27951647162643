import { IonFab, IonFabButton, IonItem, IonLabel } from "@ionic/react";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { ReactComponent as ProfileImg } from "../../../assets/iconset/icons/prof_img.svg";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import { useLang } from "../../../components/hooks/useLang";
import AdaptBox from "../../../components/shared/AdaptBox";
import AsyncValue from "../../../modules/shared/AsyncValue";
import useBoundStore from "../../../state/store";
import { ReactComponent as AddressIcon } from "../Address.svg";
import { ReactComponent as NameIcon } from "../name.svg";
import { ReactComponent as PhoneIcon } from "../phone.svg";
import { IProfileMain } from "../types";
import { EditProfilerouteName } from "./EditProfile";
export interface IProfileItemProps {
  title?: string;
  value?: ReactNode;
  leading?: ReactNode;
  align?: "horizontal" | "vertical";
}

const profileSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="#F5F5F5" />
    <path
      d="M22.6693 23.5V21.8333C22.6693 20.9493 22.3181 20.1014 21.693 19.4763C21.0678 18.8512 20.22 18.5 19.3359 18.5H12.6693C11.7852 18.5 10.9374 18.8512 10.3122 19.4763C9.68713 20.1014 9.33594 20.9493 9.33594 21.8333V23.5"
      stroke="#202020"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9974 15.1667C17.8383 15.1667 19.3307 13.6743 19.3307 11.8333C19.3307 9.99238 17.8383 8.5 15.9974 8.5C14.1564 8.5 12.6641 9.99238 12.6641 11.8333C12.6641 13.6743 14.1564 15.1667 15.9974 15.1667Z"
      stroke="#202020"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ProfileItem: FC<IProfileItemProps> = ({
  title = "Name",
  value = "Faisal Kabir",
  leading = profileSvg(),
  align = "vertical",
}) => {
  if (align === "horizontal") {
    return (
      <div className="grid grid-cols-2 mx-2 shadow-sm p-3">
        <div className="flex items-center">
          {leading}
          <div className="w-2"></div>
          <p className="text-gray-700 text-md ">{title}</p>
        </div>
        <AdaptBox textPrimary classNames="text-md">
          {value}{" "}
        </AdaptBox>
      </div>
    );
  }
  return (
    <IonItem>
      <IonLabel>
        <div className="flex">
          <div className="flex items-center">
            {leading}
            <div className="w-2"></div>
            <div className="flex-col">
              <p className="text-gray-700 text-2xl font-bold">{title}</p>
              <p className="text-gray-500 text-xl">{value}</p>
            </div>
          </div>
        </div>
      </IonLabel>
    </IonItem>
  );
};

export interface IProfileHeadersProps {
  data: IProfileMain;
  inEditMode?: boolean;
}

export const ProfileHeaders: FC<IProfileHeadersProps> = ({
  data,
  inEditMode = false,
}) => {
  const navigator = useNavigate();
  return (
    <div className="flex flex-col items-center space-y-1 my-4">
      {data?.photo ? (
        <img
          className="w-32 h-32 object-cover rounded-full"
          src={data.photo}
          alt="Profile"
        />
      ) : (
        <ProfileImg className="w-32" />
      )}

      {inEditMode ? null : (
        <AdaptBox textPrimary classNames="flex text-xl  font-medium my-2">
          {data?.name}{" "}
        </AdaptBox>
      )}
      {!inEditMode && <p className="text-gray-500 text-md">{data.email}</p>}
      <p className="text-gray-500 text-md">ID -{data?.id}</p>
    </div>
  );
};

const ProfileMainPage: FC = () => {
  const { i18n } = useTranslation();
  const { t } = useLang();

  const profileData = useSWR<IProfileMain | null>("/user");
  const navigate = useNavigate();
  const { setUser } = useBoundStore();
  // const nav = useNavigate();
  // const [isChecked, setIsChecked] = useState(false);

  return (
    <TitlePageLayout title={t("profile")} dontShowBottomNav>
      <AsyncValue<IProfileMain, any>
        res={profileData}
        onSuccess={(data) => {
          return (
            <div className="mb-8">
              <ProfileHeaders data={data} />
              {/* <select
                defaultValue={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                {availabaleLang.map((language) => (
                  <option key={language}>{language}</option>
                ))}
              </select> */}
              {/* <Toggler
                isChecked={isChecked}
                onClick={() => {
                  i18n.language === "en" && i18n.changeLanguage("bn");
                  i18n.language === "bn" && i18n.changeLanguage("en");
                }}
              /> */}
              <div className="flex flex-col space-y-4">
                <ProfileItem
                  title={t("name__")}
                  value={data?.name ?? ""}
                  leading={<NameIcon className="w-8" />}
                />
                <ProfileItem
                  leading={<PhoneIcon className="w-8" />}
                  title={t("phone__number")}
                  value={data?.phone}
                />

                <ProfileItem
                  title={t("address")}
                  value={data?.pre_addr ?? "No Address."}
                  leading={<AddressIcon className="w-8" />}
                />
              </div>
              <div className="h-4"></div>

              <IonFab slot="fixed" vertical="bottom" horizontal="end">
                <IonFabButton color={"success"}>
                  <CiEdit
                    size={40}
                    onClick={() =>
                      navigate(EditProfilerouteName, {
                        relative: "path",
                      })
                    }
                  />
                </IonFabButton>
              </IonFab>
            </div>
          );
        }}
      />
      <div className="flex justify-center items-center">
        <AdaptBox
          // goRoute={"/logout"}
          gradientPrimary
          classNames="btn w-1/3  mb-6 mt-3"
          onClick={() => {
            setUser(undefined);
            localStorage.removeItem("token");

            try {
              console.log("logout out");
              (window as any).DanaInterface.onValueReceived("logout");
            } catch (e) {
              console.log(e);
            }

            try {
              (window as any).webkit.messageHandlers.bridge.postMessage(
                "logout"
              );
            } catch (e) {
              console.log(e);
            }
            window.location.href = "https://app.dana.money/#/logout";
          }}
        >
          {t("sign__out")}
        </AdaptBox>
      </div>
    </TitlePageLayout>
  );
};
export default ProfileMainPage;
