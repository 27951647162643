import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import NoItem from "../../assets/lottie/noitem.json";
import SvgAnimation from "../../components/shared/SvgAnimation";

export interface INoItemFoundProps {
  message?: ReactNode;
  anim?: ReactNode;
}

const NoItemFound: FC<INoItemFoundProps> = ({
  message,

  anim = <SvgAnimation animationData={NoItem} loop={false} />,
}) => {
  const { t } = useTranslation();

  const mess = message || <p className="text-center">{t("no__item__found")}</p>;
  return (
    <div className="h-[100vh] flex-col justify-center items-center">
      {anim}
      {mess}
    </div>
  );
};
export default NoItemFound;
