import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Acknowlage } from "../../assets/acknowlage.svg";
import FullPageContent, { FullPageContentProps } from "./FullPageContent";
interface AcknowlagmentProps extends Omit<FullPageContentProps, "icon"> {}

const Acknowlagment: React.FC<AcknowlagmentProps> = ({ ...restProps }) => {
  const nav = useNavigate();
  const { t } = useTranslation("bnpl");
  return (
    <>
      <FullPageContent
        icon={<Acknowlage />}
        {...restProps}
        onlyNext
        onSkipClick={() => nav(-1)}
      >
        <div className="text-center">
          <h1 className="text-xl font-bold">{t("hey_there_!!!")}</h1>
          <div className="h-4" />
          <p className="text-gray-500">
            {t("confirm_some_information_to_place_your_bnpl_order")}
          </p>
        </div>
      </FullPageContent>
    </>
  );
};

export default Acknowlagment;
