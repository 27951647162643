
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { CiBank, CiWallet } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { RouterItemEnum } from '../../../router/path';
import TitlePageLayout from '../../Layouts/TitlePageLayout';
export interface IAccountMainProps {
}


const AccountMainItem: FC<PropsWithChildren<{ title: string, to: string }>> = ({ title, to, children }) => {
    return (<Link className="flex py-3 px-2 shadow-md mx-4 rounded-lg justify-between" to={to}>
        <div className="flex space-x-6">
            {children}
            <p className='text-xl font-medium text-gray-400'>{title}</p>
        </div>
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.79922 1.5999L10.1992 9.9999L1.79922 18.3999" stroke="url(#paint0_linear_1453_6442)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_1453_6442" x1="10.1992" y1="16.5242" x2="1.9561" y2="16.5857" gradientUnits="userSpaceOnUse">
                    <stop offset="0.526042" stop-color="#008081" />
                    <stop offset="1" stop-color="#006766" />
                </linearGradient>
            </defs>
        </svg>
    </Link>);
}


const AccountMain: FC<IAccountMainProps> = () => {
    const { t } = useTranslation()
    return (
        <TitlePageLayout title={'Account'} dontShowBottomNav>
            <div className='mt-10'>
                <AccountMainItem to={RouterItemEnum.AddWallet} title={t('wallet')}>
                    <CiBank className='text-2xl text-gray-400' />
                </AccountMainItem>
                <AccountMainItem to={RouterItemEnum.AddBank} title={t('bank')}>
                    <CiWallet className='text-2xl text-gray-400' />
                </AccountMainItem>
            </div>
        </TitlePageLayout>
    );
}
export default AccountMain
