import { FC } from "react";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useBoundStore from "../../../state/store";
import Rescore from "./Rescore";
export const CheckScrore: FC = () => {
  const { primaryColor, useStatus } = useBoundStore();
  // const sendEvent = useUserEventAnalytics();
  const { t } = useTranslation("main");

  if (useStatus?.score !== 0) {
    return <Rescore />;
  }

  return (
    <Link
      // onClick={() => {
      //   sendEvent({
      //     action: "new_score_start",
      //   });
      // }}
      to={"/score/new"}
      className="w-[30vw] h-[30vw] rounded-full  flex justify-center items-center shadow-kGreen-light shadow-sm"
      style={{
        backgroundColor: primaryColor,
      }}
    >
      <p className="text-center text-sm text-white font-bold">
        {t("build_score")}
      </p>
    </Link>
  );
};
