import { lazy } from "react";
import { RouterItemEnum } from "../../../router/path";
const CreditLimitPage = lazy(() => import("../pages/CreditLimit"));
const Psycometric = lazy(() => import("../pages/Psycometric"));
const VariableFormHolder = lazy(() => import("../pages/VariableDependcy"));

export const scoreRoutes = [
  {
    path: "new",
    element: <VariableFormHolder />,
  },

  {
    path: RouterItemEnum.Psycometric,
    // loader: PsycoLoader,
    element: <Psycometric />,
  },
  {
    path: RouterItemEnum.CreditLimitShow,
    // loader: PsycoLoader,
    element: <CreditLimitPage />,
  },
];
