import React from "react";
import { useTranslation } from "react-i18next";
interface PaymentItemProps {
  amount: string;
  installmentNo: string;
  timeSince?: string;
  hasOverDue?: boolean;

  action?: React.ReactNode;
  footerTxt?: React.ReactNode;
}

const PaymentItem: React.FC<PaymentItemProps> = ({
  footerTxt,
  installmentNo,
  timeSince,
  amount,
  action,
  hasOverDue,
}) => {
  const { t } = useTranslation("bnpl");

  const paymentAction = action || (
    <p className="px-5 py-1.5 bg-kGreen-light text-white rounded-lg">
      {t("pay_now")}
    </p>
  );
  return (
    <section
      className={`${
        Boolean(hasOverDue) ? "border-kAmber" : "border-kGreen-dark "
      }  border-2 p-4 m-4 rounded-lg`}
    >
      <div className="flex items-end justify-between pb-3 border-b-2 border-b-kGreen-dark">
        <p>
          {t("installment_no")} {installmentNo}
        </p>
        <p className="px-5 py-1 text-xs bg-kGreen-light text-white rounded-lg">
          {timeSince}
        </p>
      </div>
      <div className="flex justify-between p-4 items-center">
        <p className="text-2xl font-semibold">{amount} </p>
        {paymentAction}
      </div>
      {footerTxt}
    </section>
  );
};

export default PaymentItem;
