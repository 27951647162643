import { lazy } from "react"
import { RouteObject } from "react-router-dom"
const CardDetailsPage = lazy(() => import("../pages/cardDetails.page"))
const CreditCardAgreement = lazy(() => import("../pages/CreditCardAgreement"))
const CreditCardHomePagePage = lazy(() => import("../pages/home.page"))
const UpdateDocumentPage = lazy(() => import("../pages/UpdateDoc.page"))

export const enum RouterCreditCard {
    CreditCard = '/credit-card',
    CreditCardHome = '/credit-card/home',
    RsHome = "home",
    RsDetails = "details",
    RsDocument = "document",
    RsAgreement = 'agreement',
    CreditCardAgreement = '/credit-card/agreement',
    CreditCardDetails = '/credit-card/details',
    CreditDocuments = '/credit-card/document',
}

const creditCardRoutes: RouteObject[] | undefined = [
    {
        path: RouterCreditCard.RsHome,
        element: <CreditCardHomePagePage />
    },
    {
        path: RouterCreditCard.RsDetails,
        element: <CardDetailsPage />
    },
    {
        path: RouterCreditCard.RsDocument,
        element: <UpdateDocumentPage />
    },
    {
        path: RouterCreditCard.RsAgreement,
        element: <CreditCardAgreement />
    }

]



export default creditCardRoutes