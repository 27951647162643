import { Checkbox, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";
import useBoundStore from "../../../state/store";

interface Option {
  label: string;
  value: string;
}

interface MultipleSelectCheckboxProps {
  options: Option[];
  className?: string;
  initSelectedValues?: string[];
  onChange: (values: string[]) => void;
}

export const MultipleSelectCheckbox: React.FC<MultipleSelectCheckboxProps> = ({
  options,
  onChange,

  className,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { filterParmas } = useBoundStore();

  const isEnableFilter = Boolean(filterParmas?.category_id);

  useEffect(() => {
    if (isEnableFilter) {
      setSelectedValues(filterParmas?.category_id?.split(",") ?? []);
    } else {
      setSelectedValues([]);
    }
  }, [isEnableFilter, setSelectedValues]);

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = [...selectedValues];
    if (newSelectedValues.includes(value)) {
      const index = newSelectedValues.indexOf(value);
      newSelectedValues.splice(index, 1);
    } else {
      newSelectedValues.push(value);
    }
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };
  console.log("selectedValues", selectedValues);

  return (
    <div className={className}>
      {options.map((option) => (
        <div className="focus:ring-kGreen-light" key={option.value}>
          <Checkbox
            id={option.value}
            value={option.value}
            checked={selectedValues.includes(option.value)}
            onChange={(e) => handleCheckboxChange(e.target.value)}
          />
          <Label htmlFor={option.value}>
            <span className="text-sm ml-2">{option.label}</span>
          </Label>
        </div>
      ))}
    </div>
  );
};

// // Example usage:
// const options: Option[] = [
//   { label: "Option 1", value: "option1" },
//   { label: "Option 2", value: "option2" },
//   { label: "Option 3", value: "option3" },
// ];

// const App: React.FC = () => {
//   const handleSelectionChange = (selectedValues: string[]) => {
//     console.log("Selected values:", selectedValues);
//   };

//   return (
//     <div className="p-4">
//       <h1 className="text-2xl font-bold mb-4">
//         Multiple Select Checkbox Example
//       </h1>
//       <MultipleSelectCheckbox
//         options={options}
//         onChange={handleSelectionChange}
//       />
//     </div>
//   );
// };

// export default App;
