import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RouterItemEnum } from "../../../router/path";
import { BNPLAddressVerifyPagerouteName } from "../pages/AddressVerify";

import { BNPLDetailsrouteName } from "../pages/BNPLDetails";
import { CPVDonerouteName } from "../pages/CPVDone";
import { BNPLFaceUploadrouteName } from "../pages/FaceUpload";
import { PaylaterEligiblerouteName } from "../pages/PaylaterEligible";
import { PaymentSchedulerouteName } from "../pages/PaymentSchedule";
import { BNPLDocumentUploadrouteName } from "../pages/UploadDocuments/UploadDocument";
const PaylaterEligible = lazy(() => import("../pages/PaylaterEligible"));
const BNPLUploadDocumentPage = lazy(
  () => import("../pages/UploadDocuments/UploadDocument")
);
const BNPLFaceUploadPage = lazy(() => import("../pages/FaceUpload"));
const BNPLAddressVerify = lazy(() => import("../pages/AddressVerify"));
const BNPLDetails = lazy(() => import("../pages/BNPLDetails"));
const PaymentSchedule = lazy(() => import("../pages/PaymentSchedule"));
const CPVDone = lazy(() => import("../pages/CPVDone"));

const OrderDetails = lazy(() => import("../Order/pages/OrderDetails"));
const OrderHistory = lazy(() => import("../Order/pages/OrderHistory"));
const AdressInput = lazy(() => import("../pages/AdressInput"));
const AllProduct = lazy(() => import("../pages/AllProduct"));
const FilterBrandProductShow = lazy(
  () => import("../pages/FilterBrandProductShow")
);
const MainBnpl = lazy(() => import("../pages/MainBnpl"));
const Overview = lazy(() => import("../pages/Overview"));
const PaylaterAgreement = lazy(() => import("../pages/PaylaterAgreement"));
const PayLaterApplication = lazy(() => import("../pages/PayLaterApplication"));
const PayLaterDetails = lazy(() => import("../pages/PayLaterDetails"));
const ProductDetails = lazy(() => import("../pages/ProductDetails"));
const RegionWarn = lazy(() => import("../pages/RegionWarn"));

const paylaterRoutes: RouteObject[] | undefined = [
  {
    path: RouterItemEnum.main,
    children: [
      {
        index: true,
        element: <MainBnpl />,
      },
    ],
  },
  {
    path: PaylaterEligiblerouteName,
    element: <PaylaterEligible />,
  },
  {
    path: BNPLDocumentUploadrouteName,
    element: <BNPLUploadDocumentPage />,
  },
  {
    path: BNPLFaceUploadrouteName,
    element: <BNPLFaceUploadPage />,
  },
  {
    path: BNPLAddressVerifyPagerouteName,
    element: <BNPLAddressVerify />,
  },
  {
    path: BNPLDetailsrouteName,
    element: <BNPLDetails />,
  },
  {
    path: PaymentSchedulerouteName,
    element: <PaymentSchedule />,
  },
  {
    path: CPVDonerouteName,
    element: <CPVDone />,
  },
  {
    path: RouterItemEnum.allProduct,
    element: <AllProduct />,
  },

  {
    path: RouterItemEnum.ProductDetails,
    element: <ProductDetails />,
  },
  {
    path: RouterItemEnum.Applicatio,
    element: <PayLaterApplication />,
  },
  {
    path: RouterItemEnum.OverView,
    element: <Overview />,
  },
  {
    path: RouterItemEnum.DevivaryAddress,
    element: <AdressInput />,
  },
  {
    path: RouterItemEnum.DevilaryOverview,
    element: <ProductDetails />,
  },
  {
    path: RouterItemEnum.Info,
    element: <PayLaterDetails />,
  },
  {
    path: RouterItemEnum.OrderHistory,
    element: <OrderHistory />,
  },
  {
    path: RouterItemEnum.OrderHistoryDetails,
    element: <OrderDetails />,
  },
  {
    path: RouterItemEnum.RegionWarn,
    element: <RegionWarn />,
  },
  {
    path: RouterItemEnum.FilterProduct,
    element: <FilterBrandProductShow />,
  },
  {
    path: RouterItemEnum.PaylaterAgreement,
    element: <PaylaterAgreement />,
  },
];

export default paylaterRoutes;
