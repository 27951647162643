import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import useGoogleAnalytics from "../../analytics/analytics_hook";
import { useGetHttpClient } from "../../client/axios";
import { RouterItemEnum } from "../../router/path";
import { UserInfo } from "../../state";
import useBoundStore from "../../state/store";
import { useIonAlert } from "@ionic/react";
import { useUserEventAnalytics } from "../../analytics/utils";


export const useInitializeAndHandlePayment = () => {
  const [searchParams] = useSearchParams();
  const sendEvent = useUserEventAnalytics()
  const { setUser,payingEarlySettlement} = useBoundStore();
  const [presentAlert] = useIonAlert();
  const navigator = useNavigate();
  const isBkashPayment =Boolean(searchParams.get("paymentID")) 
  const isNagadPayment =Boolean(searchParams.get("payment_ref_id")) 
  const client = useGetHttpClient();
  const paymentParams = {
    ...Object.fromEntries(searchParams.entries()),
  }

  const handlePayment= (type:'bkash'|'nagad')=>{
    client(`/loan/repay/pg/${type}/complete`,"GET",{
      params:paymentParams
    }).then(req =>{
      
        presentAlert({
          header: `Payment Successful !`,
          message: `Your payment was ${(req as any)["statusMessage"]}`,
          buttons: ['OK'],
         
        })
        console.log(req)
      
        }).catch(e=>{
          console.log(e)
          
            presentAlert({
                header: `Payment Failed !`,
                message: e?.message ?? "Your payment failed. Please try again.",
                buttons: ['OK'],
              
            })
        }).finally(() => {
            console.log(payingEarlySettlement)
            navigator("/home", { replace: true })
            return () => { }
        })
  }

  useGoogleAnalytics();
  
  const verifyRes = useSWR<UserInfo>(
    searchParams.get("h") ? `userFetch` : null,
    ()  => {
      return client("/sdk/web/verify", "POST", {
        data: {
          h: searchParams.get("h"),
          cid: searchParams.get("cid"),
        },
      }

      
    );
  },
    {
      errorRetryCount: 4,
      onSuccess(data) {
        if (data) {
          setUser(data as any);
          localStorage.setItem("userToken", data?.token ?? "");
          try {
            
            sendEvent({
              action:"login",
              label:data["username"],
              data:data["id"]
            })
          } catch (error) {
            
          }
          navigator(RouterItemEnum.home, { replace: true });
        }
      },
    }
  );

  useEffect(() => {
    if(isBkashPayment){
      handlePayment('bkash')
    } 
    if(isNagadPayment){
      handlePayment('nagad')
    }

   


  
  
  return () => { }
    
  }, [searchParams]);
  return { verifyRes };
};


// [Axios][Request] POST /loan/repay/pg/nagad/complete {"status":"Success","payment_ref_id":"MDUyNDEwNTUzNTQ1Mi42ODMwMDIwMDcxMDQyMjUuMjE0MFRTMTA1NTM1LmZlODk3YzFkNGEwZTNkMzNlNGQ5","order_id":"2140TS105535"}

//BKASH PAYMENT /?paymentID=TR0011SY1685436323487&status=cancel&apiVersion=1.2.0-beta
 