
import { RouterItemEnum } from "../router/path";
import Rescore from "../pages/Home/components/Rescore";
import { CheckScrore } from "../pages/Home/components/CheckScrore";

export const  mapPathToCompForCover = (path:string)=>{
    switch (path) {
        case RouterItemEnum.home:
            return CheckScrore
            
        default:
            return Rescore;
    }
    
}


export const routerMatcher = (activeRoute:string,matchRoute:string):boolean => activeRoute.replace("/","").trim()=== matchRoute.replace("/","").trim()
    
export const totitleCase = (s:string) =>
        s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
         .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());

export function generateList<T>(length: number, callback: (index: number) => T): T[] {
    const result: T[] = [];
    for (let i = 0; i < length; i++) {
      result.push(callback(i));
    }
    return result;
  }
  