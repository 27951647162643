import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import httpClient from "../../../../client/axios";
import useBoundStore from "../../../../state/store";
import NoItemFound from "../../../shared/NoItemFound";
import { IProduct } from "../../types";

const useDebouncedSearch = (callback: Function, delay: number) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      callback(searchTerm);
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchTerm, callback, delay]);

  return {
    searchTerm,
    handleInputChange,
  };
};

interface DebounceSearchProps {}

const DebounceSearch: React.FC<DebounceSearchProps> = () => {
  const { t } = useTranslation("bnpl");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const nav = useNavigate();
  // const [searchTerm, setSearchTerm] = useState("");
  // const [suggestedResults, setSuggestedResults] = useState<string[]>([]);
  const [suggestedResults, setSuggestedResults] = useState<IProduct[]>([]);

  const { toggleFilterEnablity, setFilterParams, fetchfilterProduct } =
    useBoundStore();

  const fetchSuggestedResults = async (term: string) => {
    if (term.length < 3) return;
    const simulatedResults = await httpClient
      .get<any, any>("/bnpl/products?limit=5&&search=" + term)
      .then((res) => {
        if (res.data.error) {
          setError(res.data.error);
          return;
        }
        setSuggestedResults(res.data.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const debounce = (func: (arg: string) => void, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (arg: string) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(arg);
      }, delay);
    };
  };

  const debouncedHandleChange = debounce((value: string) => {
    // This code will be executed after a 300 ms delay
    fetchSuggestedResults(value);
  }, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);

    // Call the debounced function with the updated value
    debouncedHandleChange(value);
  };

  const handleWhenEnterPressed = () => {
    toggleFilterEnablity(true);
    setFilterParams({
      search: inputValue,
      limit: 300,
    });
    fetchfilterProduct(
      {
        search: inputValue,
        limit: 300,
      },
      undefined,
      undefined,
      true
    );
  };

  // const { searchTerm, handleInputChange } = useDebouncedSearch(
  //   fetchSuggestedResults,
  //   300
  // );

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newSearchTerm = e.target.value;
  //   setSearchTerm(newSearchTerm);

  //   const simulatedResults = [
  //     "Result 1",
  //     "Result 2",
  //     "Result 3",
  //     "Result 4 ",
  //   ].filter((result) =>
  //     result.toLowerCase().includes(newSearchTerm.toLowerCase())
  //   );
  //   setSuggestedResults(simulatedResults);
  // };

  return (
    <div className="relative w-full mx-4">
      <input
        type="search"
        value={inputValue}
        className="block w-full  px-2 mr-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-600 focus:border-teal-600 "
        placeholder={t("search_products,_brands,_categories")}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.currentTarget.blur();
            handleWhenEnterPressed();
            setInputValue("");
            setSuggestedResults([]);
          }
        }}
      />
      {/* {searchTerm && isSearching && <Spinner />} */}
      {/* {searchTerm && error && <AlertPrimary>{error}</AlertPrimary>} */}

      {inputValue && suggestedResults.length > 0 && (
        <ul className="absolute z-10 mt-2 py-1 bg-white border rounded shadow-md w-full">
          {suggestedResults.map((result, index) => (
            <li
              key={index}
              onClick={() => nav("/payLater/product/" + result.id)}
              className="px-4 py-2 cursor-pointer hover:bg-blue-100 divide-y-1 "
              dangerouslySetInnerHTML={{
                __html: result.name.replace(
                  new RegExp(`(${inputValue})`, "gi"),
                  "<strong>$1</strong>"
                ),
              }}
            />
          ))}
        </ul>
      )}
      {inputValue && suggestedResults.length === 0 && (
        <ul className="absolute z-10 mt-2 py-1 bg-white border rounded shadow-md w-full">
          <NoItemFound
            message={<p className="text-center">No Product Found</p>}
          />
        </ul>
      )}
    </div>
  );
};

export default DebounceSearch;
