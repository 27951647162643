import { t } from "i18next";
import { lazy } from "react";
import { Link, RouteObject } from "react-router-dom";
import TitlePageLayout from "../components/Layouts/TitlePageLayout";
import { AboutUsPagerouteName } from "../pages/Help";
import { OtpVerificationPageRoute } from "../pages/Login/components/OtpVerificationPage";
import { EditProfilerouteName } from "../pages/Profile/pages/EditProfile";
import { ProfileInputrouteName } from "../pages/Profile/pages/ProfileInput";
import { RouterItemEnum, makeRoute } from "./path";
import { makePath } from "./utils";
const ChooseColor = lazy(() => import("../components/pages/Color/ChooseColor"));
const MorePage = lazy(() => import("../components/pages/More/page"));
const LoanDetails = lazy(() => import("../modules/loan/pages/LoanDetails"));
const MyScrorePage = lazy(
  () => import("../modules/score/pages/MyScore/Myscore")
);
const AboutUsPage = lazy(() => import("../pages/Help"));
const Disbursed = lazy(() => import("../modules/shared/Disbursed"));

const PaymentSchedule = lazy(() => import("../modules/shared/PaymentSchedule"));
const Rescore = lazy(() => import("../pages/Home/components/Rescore"));
const HowDanaWorks = lazy(() => import("../pages/HowDanaWorks/HowDanaWorks"));
const InitRoute = lazy(() => import("../pages/InitRoute"));
const EditProfile = lazy(() => import("../pages/Profile/pages/EditProfile"));
const ProfileInput = lazy(() => import("../pages/Profile/pages/ProfileInput"));
const OtpVericationPage = lazy(
  () => import("../pages/Login/components/OtpVerificationPage")
);
const ProfileMainPage = lazy(
  () => import("../pages/Profile/pages/ProfileMain")
);
const StateMentMain = lazy(
  () => import("../pages/Statements/pages/StateMentMain")
);
const WhatIsCreditScore = lazy(() => import("../pages/WhatIsCreditScore/page"));
const NotificationPage = lazy(
  () => import("../pages/Notification/pages/NotificationPage")
);

export const commonRoutes: RouteObject[] = [
  makePath(
    RouterItemEnum.Disbursed,
    <TitlePageLayout dontShowBottomNav title="Disbursment Details">
      <Disbursed />
    </TitlePageLayout>
  ),
  makePath(
    makeRoute([RouterItemEnum.loan, RouterItemEnum.Info]),
    <TitlePageLayout title="Congratulation" dontShowBottomNav>
      <LoanDetails />
    </TitlePageLayout>
  ),
  {
    path: OtpVerificationPageRoute,
    element: <OtpVericationPage />,
  },

  { path: RouterItemEnum.Rescore, element: <Rescore /> },

  {
    path: RouterItemEnum.notification,
    element: <NotificationPage />,
  },
  {
    path: RouterItemEnum.stateMent,
    element: (
      <TitlePageLayout title={t("activity")}>
        <StateMentMain />
      </TitlePageLayout>
    ),
  },
  {
    path: RouterItemEnum.profile,
    element: <ProfileMainPage />,
  },
  {
    path: AboutUsPagerouteName,
    element: <AboutUsPage />,
  },
  {
    path: ProfileInputrouteName,
    element: <ProfileInput />,
  },
  {
    path: EditProfilerouteName,

    element: <EditProfile />,
  },
  {
    path: RouterItemEnum.ColorPicker,
    element: (
      <TitlePageLayout title={"Pick Primary Color"} dontShowBottomNav>
        <ChooseColor />
      </TitlePageLayout>
    ),
  },
  makePath(RouterItemEnum.MyScore, <MyScrorePage />),
  makePath(RouterItemEnum.More, <MorePage />),
  {
    path: AboutUsPagerouteName,
    element: <AboutUsPage />,
  },
  // makePath('test', <TestRoute />),
  makePath("payment", <PaymentSchedule />),

  makePath(RouterItemEnum.HowItWorksDana, <HowDanaWorks />),
  makePath(RouterItemEnum.WhatisCreditScore, <WhatIsCreditScore />),
  {
    path: "/init/:h/:cid",
    errorElement: (
      <div>
        <p className="text-center text-2xl">Somethings Went Wrong</p>
        <Link to="/" className="text-center text-red-500">
          Go to Home
        </Link>
      </div>
    ),

    element: <InitRoute />,
  },
];
