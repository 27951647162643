
import { FC } from 'react';
import { RadioItem } from '../../../modules/paylater/components/SearchAndFilter';
import { makeRoute, RouterItemEnum } from '../../../router/path';
import TitlePageLayout from '../../Layouts/TitlePageLayout';
import AdaptBox from '../../shared/AdaptBox';
import SelectOption from '../../shared/Form/DropDown';
import TextInput from '../../shared/Form/TextInput';

export interface IAddBankProps {
}

const AddBank: FC<IAddBankProps> = () => {
    return (
        <TitlePageLayout title={'Provide Bank Details'} dontShowBottomNav >
            <div className='my-6 px-6'>
                <legend>Bank account type?</legend>
                <div className="flex gap-4 mt-3">
                    <RadioItem label='Personal' name='actp' />
                    <RadioItem label='Business' name='actp' />
                </div>
                <div className='my-6 '>
                    <SelectOption label={'Bank Name'} name={'bankName'} options={["IBBL", "Agrani", "Pubali", "Rupali", "Sonali"]} />
                    <TextInput label={'Branch Name'} name={'brnname'} placeHolder={'Type here..'} />
                    <TextInput label={'Account Holder Name'} name={'ausername'} placeHolder={'e.g: John Smith'} />
                    <TextInput label={'Account Number'} name={'accnum'} placeHolder={'e.g: 23738744384734'} />
                </div>
                <div className="flex flex-col justify-center items-center h-[35vh]">
                    <div className="flex-1"></div>
                    <AdaptBox classNames='btn w-1/2 text-white' onlyBg goRoute={makeRoute([RouterItemEnum.common, RouterItemEnum.thankYou])}>Confrim</AdaptBox>
                </div>
            </div>
        </TitlePageLayout>);
}
export default AddBank
