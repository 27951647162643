import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useBoundStore from "../../../../state/store";
import { getPriceWithComma } from "../../../../utils/en2bn";
interface MakeFilterProps {}

const mapKeyToLabel = {
  category_name: "Category",
  name: "Search",
  min: "Lower Price",
  max: "Upper Price",
};

const MakeFilter: React.FC<MakeFilterProps> = () => {
  const { filterParmas, setFilterParams, fetchfilterProduct } = useBoundStore();
  const { t } = useTranslation("bnpl");

  useEffect(() => {
    console.log(filterParmas, "filterParamas");
  }, [filterParmas]);

  const hasFilter =
    Boolean(filterParmas?.name) ||
    Boolean(filterParmas?.search) ||
    Boolean(filterParmas?.category_name) ||
    Boolean(filterParmas?.category_id) ||
    Boolean(filterParmas?.min) ||
    Boolean(filterParmas?.max);

  if (!hasFilter) return null;

  return (
    <div className="mx-4 border-t border-b  mt-2 pt-3 ">
      <div className="flex items-center justify-between">
        <p>{t("filters")}</p>
        <button
          className="text-xs text-kGreen-light"
          onClick={() => {
            setFilterParams({ limit: 100 });
            fetchfilterProduct({ limit: 100 }, undefined, 0);
          }}
        >
          {t("clear_all")}
        </button>
      </div>

      <div className="flex flex-wrap items-center ">
        {Boolean(filterParmas?.name) ? (
          <div className="px-4 py-1 m-2 bg-gray-50 shadow-sm text-sm  border rounded-full ">
            {filterParmas.name}
          </div>
        ) : null}

        {Boolean(filterParmas?.search) ? (
          <div className="px-2 text-center py-1 m-2 bg-gray-50 shadow-sm text-sm  border rounded-full ">
            {filterParmas.search}
          </div>
        ) : null}

        {Boolean(filterParmas?.category_name) ? (
          <>
            {filterParmas.category_name?.split(",")?.map((category: string) => (
              <div
                key={category}
                className="px-2 text-center py-1 m-2 bg-gray-50 shadow-sm text-sm  border rounded-full "
              >
                {category}
              </div>
            ))}
          </>
        ) : null}

        {Boolean(filterParmas?.min) && Boolean(filterParmas?.max) ? (
          <div className="px-4 py-1 m-2 bg-gray-50 shadow-sm text-sm  border rounded-full ">
            {getPriceWithComma(filterParmas.min)} -{" "}
            {parseInt(filterParmas.max ?? "0").toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            })}
          </div>
        ) : null}

        <div />
      </div>
    </div>
  );
};

export default MakeFilter;
