import { IonFab, IonFabButton } from "@ionic/react";
import { Modal } from "flowbite-react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { ChangeEventHandler, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiFilter } from "react-icons/ci";
import AdaptBox from "../../../components/shared/AdaptBox";
import useBoundStore from "../../../state/store";
import { getPriceWithComma } from "../../../utils/en2bn";
import { useReuqestImmutable } from "../../hook/useRequest";
import AsyncValue from "../../shared/AsyncValue";
import BtnPrimary from "../../shared/BtnPrimary";
import { ICategories } from "../types";
import { MultipleSelectCheckbox } from "./MultiSelecCheckBox";
import { DebounceSearch } from "./components";

export const RadioItem: FC<{
  label: string;
  name?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  checked?: boolean;
}> = ({ label, name = "categories", value, onChange, checked }) => {
  return (
    <div className="flex items-center gap-2">
      <input
        id={name}
        onChange={onChange}
        type="radio"
        value={value ?? label.toLocaleLowerCase()}
        name={name}
        className="w-4 h-4 text-kGreen-light bg-gray-100 border-gray-300 focus:bg-kGreen-light dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        checked={checked}
      />
      <label
        htmlFor={name}
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {label}
      </label>
    </div>
  );
};

export const PriceSlider: FC<{
  min: number;
  max: number;
  onChange: (min: number, max: number) => void;
}> = ({ min, max, onChange }) => {
  const { t } = useTranslation();
  const [range, setRange] = useState<number[]>([0, 0]);
  const { filterParmas } = useBoundStore();

  useEffect(() => {
    if (Boolean(filterParmas.min) && Boolean(filterParmas.max)) {
      setRange([
        parseInt(filterParmas.min ?? "0"),
        parseInt(filterParmas.max ?? "0"),
      ]);
    } else {
      setRange([0, 500000]);
    }
  }, [filterParmas, setRange]);
  console.log(
    range,
    "range",
    filterParmas.min,
    filterParmas.max,
    "filterParmas"
  );

  return (
    <div className="my-4">
      <div className="flex flex-col justify-center items-center">
        <p>{t("price__range")}</p>

        <AdaptBox onlyTxt>
          {`${getPriceWithComma(range[0] ?? 0)} - ${getPriceWithComma(
            range[1] ?? 0
          )}`}
        </AdaptBox>
      </div>
      <Slider
        onChange={(val) => {
          const [minval, maxVal] = val as number[];
          setRange(val as number[]);

          onChange(minval, maxVal);
        }}
        min={min}
        max={max}
        range
        value={range}
      />
      <div className="flex justify-between">
        <p>{getPriceWithComma(min)}</p>
        <p>{getPriceWithComma(max)}</p>
      </div>
    </div>
  );
};

const SearchAndFilter: FC = () => {
  const [showModal, setshowModal] = useState(false);
  const [cat, setcat] = useState<string[]>([]);
  const [search, setSearch] = useState<string>();
  const {
    fetchfilterProduct,
    setFilterParams,
    filterParmas,
    toggleFilterEnablity,
    useStatus,
  } = useBoundStore();
  const [rangVal, setRangeVal] = useState<number[]>([
    0,
    parseInt(useStatus?.credit_limit ?? "100000"),
  ]);
  const { t } = useTranslation("bnpl");

  const [allCat, setAllCat] = useState<ICategories[]>([]);
  const cateReq = useReuqestImmutable("/bnpl/products/categories");

  return (
    <div className="flex justify-around mt-6">
      <DebounceSearch />
      {/* <div className=" w-full mx-4">
        <input
          type="search"
          value={search}
          className="block w-full  px-2 mr-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-600 focus:border-teal-600 "
          placeholder={t("search_products,_brands,_categories")}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur();
              toggleFilterEnablity(true);
              setFilterParams({ ...filterParmas, name: search, limit: 300 });
              fetchfilterProduct(
                { ...filterParmas, name: search, limit: 300 },
                undefined,
                undefined,
                true
              );
            }
          }}
        />
      </div> */}

      <div className="fixed bottom-8 right-2">
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton
            color={"success"}
            onClick={() => setshowModal(!showModal)}
          >
            <CiFilter className="stroke-gray-50 w-8 h-6" />
          </IonFabButton>
        </IonFab>
      </div>

      {/* <div className="p-1 shadow-md  bg-kGreen-light text-white rounded-md mx-2 flex items-center ">
      I</div> */}

      {/* <AdaptBox onlyBg classNames=" flex justify-center items-center p-3 rounded-md mx-3" onClick={() => setshowModal(!showModal)}  >

        </AdaptBox> */}
      <Modal show={showModal} onClose={() => setshowModal(!setshowModal)}>
        <Modal.Header>
          <div className="flex justify-center items-center w-full">
            {t("filter")}
          </div>
        </Modal.Header>
        <Modal.Body>
          <AsyncValue<ICategories[], any>
            res={cateReq}
            onSuccess={function (data: ICategories[]): JSX.Element {
              setAllCat(data);
              return (
                <div>
                  {Boolean(filterParmas?.category_id) ? (
                    <MultipleSelectCheckbox
                      className="grid grid-cols-2 gap-6 max-h-[40vh] overflow-y-auto"
                      options={data.map((it) => {
                        return { label: it.name, value: it.id };
                      })}
                      onChange={(val) => setcat(val)}
                      initSelectedValues={filterParmas?.category_id?.split(",")}
                    />
                  ) : (
                    <MultipleSelectCheckbox
                      className="grid grid-cols-2 gap-6 max-h-[40vh] overflow-y-auto"
                      options={data.map((it) => {
                        return { label: it.name, value: it.id };
                      })}
                      onChange={(val) => setcat(val)}
                    />
                  )}

                  {/* {data.map((e) => (
                    <div className="focus:ring-kGreen-light">
                      <Checkbox
                        id={e.id}
                        checked={e.id === cat}
                        value={e.id}
                        onChange={(e) => setcat(e.target.value)}
                      />
                      <Label htmlFor={e.id}>
                        <span className="text-sm ml-2">{e.name}</span>
                      </Label>
                    </div>
                    // <RadioItem
                    //   label={e.name}
                    //   name={"categories"}
                    //   checked={e.id === cat}
                    //   value={e.id}
                    //   onChange={(e) => setcat(e.target.value)}
                    // />
                  ))} */}
                </div>
              );
            }}
          />

          <div className="h-4" />
          <hr />

          <PriceSlider
            min={0}
            max={500000}
            onChange={function (min: number, max: number): void {
              setRangeVal([min, max]);
            }}
          />

          <BtnPrimary
            center
            onClick={() => {
              toggleFilterEnablity(true);
              setFilterParams({
                ...filterParmas,
                category_id: cat.join(","),
                category_name: cat
                  .map((id) => allCat.find((it) => it.id === id)?.name)
                  .join(","),

                min: `${rangVal[0] ?? 0}`,
                max: `${rangVal[1] ?? 5000}`,
                limit: 300,
              });
              fetchfilterProduct(
                {
                  ...filterParmas,
                  category_id: cat.join(","),
                  min: `${rangVal[0] ?? 0}`,
                  max: `${rangVal[1] ?? 5000}`,
                  limit: 300,
                },
                undefined,
                undefined,
                true
              );
              setshowModal(!showModal);
            }}
          >
            {t("done")}
          </BtnPrimary>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SearchAndFilter;
