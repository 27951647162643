import { Modal, ModalProps } from 'flowbite-react';
import { FC, useEffect, useState } from 'react';

export interface IModalChildProps extends ModalProps {

    Header?: JSX.Element
    Body?: JSX.Element
    Footer?: JSX.Element
    closeButtonOnFooter?: boolean


}



export const ModalChild: FC<IModalChildProps> = ({ children, Header, Footer, Body, show, onClose, closeButtonOnFooter, ...restProps }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            {(children! as JSX.Element).props?.onClick ? (children! as JSX.Element) : <div onClick={() => setShowModal(!showModal)}>
                {(children! as JSX.Element)}
            </div>}

            <Modal show={show ?? showModal} onClose={() => {
                if (onClose) {
                    onClose()
                } else {
                    setShowModal(!showModal)
                }
            }} position="center" {...restProps}>
                {Header && <Modal.Header>{Header}</Modal.Header>}
                {Body && <Modal.Body>{Body}</Modal.Body>}
                {Footer && <Modal.Footer>
                    {Footer && closeButtonOnFooter && <div className="mx-auto" onClick={() => setShowModal(!showModal)}>{
                        Footer.props.children
                    }</div>}
                    {!closeButtonOnFooter && Footer && Footer}
                </Modal.Footer>}
            </Modal>
        </>
    );
}

