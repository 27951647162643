import { Card } from "flowbite-react";
import { FC, ReactNode } from "react";

interface IApplyItemProps {
    icon: ReactNode
    title: string
}

export const ApplyItem: FC<IApplyItemProps> = ({ icon, title }) => {
    return (
        <div>
            <div className='flex items-center justify-center'>
                {icon}
            </div>
            <div className="h-10"></div>
            <div className="mx-4">
                <Card>
                    <p className='text-center text-gray-600 text-md'>{title}</p>
                </Card>
            </div>

        </div>
    );
}